import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "../../client/utils/withRouter";
import PerfectScrollbar from "react-perfect-scrollbar";

import { adminLinks } from "../../client/routes/index";
import logo from "../../assets/img/logo/logo-lng.png";
import SidebarCategory from "./sidebar/SidebarCategory";
import SidebarItem from "./sidebar/SidebarItem";
import SideBarLink from "./sidebar/SideBarLink";
import Loader from "./Loader";

const Sidebar = (props) => {
  const { sidebar, userPermissions } = props;
  const [toggleSettings, setToggle] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);

  const toggle = (index) => {
    Object.keys(toggleSettings).forEach((item) => {
      item = parseInt(item);
      if (index !== item) {
        toggleSettings[item] = false;
      }
    });
    toggleSettings[index] = !toggleSettings[index];
    setToggle(toggleSettings);
    setReload(!reload);
  };

  useEffect(() => {
    if (typeof props.auth !== "undefined" && props.auth !== null) {
      setLoading(false);
    }
  }, [props]);

  useEffect(() => {}, [toggleSettings]);

  if (loading) {
    return <Loader />;
  }

  return (
    <nav
      className={
        "sidebar" +
        (!sidebar.isOpen ? " toggled" : "") +
        (sidebar.isSticky ? " sidebar-sticky" : "")
      }
    >
      <div className="sidebar-content">
        <PerfectScrollbar>
          <div className="w-100 text-center mt-5">
            <h3>
              Pinellas
              <br />
              Christian Businesses
            </h3>
            <hr />
          </div>
          <ul className="sidebar-nav">
            {adminLinks.map((category, index) => {
              let permissionTable = category.langName;
              let subLinkAccess = false;
              if (category.children !== null) {
                category.children.map((route) => {
                  subLinkAccess = true;
                  return null;
                });
              }
              return (
                <Fragment key={index}>
                  {category.header ? (
                    <li className="sidebar-header">{category.header}</li>
                  ) : null}
                  {category.children && subLinkAccess !== false && (
                    <SidebarCategory
                      name={category.name}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                      icon={category.icon}
                      to={category.path}
                      isOpen={toggleSettings[index]}
                      onClick={() => toggle(index)}
                    >
                      {category.children.map((route, index) => {
                        return (
                          <SidebarItem
                            key={index}
                            name={route.name}
                            to={route.path}
                            badgeColor={route.badgeColor}
                            badgeText={route.badgeText}
                          />
                        );
                      })}
                    </SidebarCategory>
                  )}
                  {(typeof category.children === "undefined" ||
                    category.children === null) && (
                    <SideBarLink
                      permissionTable={permissionTable}
                      userPermissions={userPermissions}
                      name={category.name}
                      to={category.path}
                      icon={category.icon}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                    />
                  )}
                </Fragment>
              );
            })}
          </ul>
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default withRouter(
  connect((store) => ({
    sidebar: store.sidebar,
    layout: store.layout,
    auth: store.auth,
    userPermissions: store.auth,
  }))(Sidebar)
);
