import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

const Profile = ({ linkActive }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          Manage Profile
        </CardTitle>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem
          tag="a"
          href="/profile"
          action
          active={linkActive === "/profile"}
        >
          Profile
        </ListGroupItem>
        <ListGroupItem
          tag="a"
          href="/profile-password"
          action
          active={linkActive === "/profile-password"}
        >
          Password
        </ListGroupItem>
        <ListGroupItem
          tag="a"
          href="/profile-notifications"
          action
          active={linkActive === "/profile-notifications"}
        >
          Notifications
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

export default Profile;
