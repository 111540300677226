import React, { useEffect } from "react";
import { Provider } from "react-redux";
import store from "./client/store";
import Routes from "./client/routes/Routes";
import { loadUser } from "./client/actions/auth";

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
};
export default App;
