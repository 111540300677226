import {Link} from "react-router-dom";
import {Eye as ViewIcon} from "react-feather";
import React from "react";

const DashboardNotifications = ({notifications}) => {
	return (<div className="card">
		<div className="card-header">
			<h3>Recent Notifications</h3>
		</div>
		<div className="card-body">
			{ notifications && notifications.length !== 0 && notifications.map((notification, index) => {
				return (<div key={index} className="row text-sm mb-1">
					<div className="col-3">
						{notification.time}
					</div>
					<div className="col-6">
						{notification.notificationSubject} {' '}
						{notification.notificationMessage}
					</div>
					<div className="col-3 text-end">
						<Link to={`/mentee/manage/${notification.id}`} className="btn btn-sm btn-primary">
							<ViewIcon size={18}/>
						</Link>
					</div>
				</div>);
			})}
			{ notifications && notifications.length === 0 && <div className="row text-sm mb-1">
				<div className="col-12">
					No recent notifications
				</div>
			</div>}
		</div>
	</div>);
}
export default DashboardNotifications;
