import React, { Component } from "react";
import classnames from "classnames";
import { connect } from "react-redux";

class FormLinkedTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      results: [],
    };
  }

  componentDidMount() {
    // console.log('FormLinkedTable', this.props);
  }

  render() {
    const { field, label } = this.props;
    let fields = this.props.field;
    let results = this.props.value;
    let columns = fields.linkedTableInfo.columns;

    if (typeof results === "undefined") {
      results = [];
    }
    if (typeof columns === "undefined") {
      columns = [];
    }

    let divColSize = 0;
    let colSize = field.colSize;
    if (colSize === 0) divColSize = 0;
    if (colSize === 1) divColSize = 12;
    if (colSize === 2) divColSize = 6;
    if (colSize === 3) divColSize = 4;
    if (colSize === 4) divColSize = 8;

    return (
      <div className={`col-12 align-bottom col-sm-${divColSize}`}>
        <div className="container p-0">
          <div className="row">
            <label
              htmlFor={fields.fieldName}
              className={classnames("col-sm-12 m-0 pt-2 pb-2")}
            >
              {label}
            </label>
            <div className="col-sm-12">
              <table className="table table-sm table-striped">
                <thead className="thead-dark">
                  <tr>
                    {columns.map((column, i) => {
                      return (
                        <th key={`headerRow${i}`}>{column.displayName}</th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {results.map((result, i) => {
                    return (
                      <tr key={`dataRow${i}`}>
                        {columns.map((column, i) => {
                          return <td key={i}>{result[column.fieldName]}</td>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.user,
});

export default connect(mapStateToProps)(FormLinkedTable);
