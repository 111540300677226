import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toggleSidebarNew } from "../../client/actions/sidebarActions";
import { Link } from "react-router-dom";
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  UncontrolledDropdown,
} from "reactstrap";
import {
  HelpCircle as HelpIcon,
  Power as LogoutIcon,
  Settings,
  User,
} from "react-feather";

const NavbarComponent = (props) => {
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    if (props.auth) {
      if (props.auth.userInfo) {
        setUserInfo(props.auth.userInfo);
      }
    }
  }, [props]);

  return (
    <Navbar color="white" light expand>
      <span
        className="sidebar-toggle d-flex me-2"
        onClick={() => toggleSidebarNew()}
      >
        <i className="hamburger align-self-center" />
      </span>
      <Collapse navbar>
        <Nav className="ms-auto" navbar>
          <UncontrolledDropdown nav inNavbar>
            <span className="d-inline-block d-sm-none">
              <DropdownToggle nav caret>
                <Settings size={18} className="align-middle" />
              </DropdownToggle>
            </span>
            <span className="d-none d-sm-inline-block">
              <DropdownToggle nav caret>
                <span className="text-dark">
                  {userInfo.firstName} {userInfo.lastName}
                </span>
              </DropdownToggle>
            </span>
            <DropdownMenu end>
              <DropdownItem>
                <Link to="/profile">
                  <User size={18} className="align-middle me-2" />
                  Profile
                </Link>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem>
                <Link to="/help-center">
                  <HelpIcon size={18} className="align-middle me-2" />
                  Help
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link to="/auth/sign-out">
                  <LogoutIcon size={18} className="align-middle me-2" />
                  Sign Out
                </Link>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(
  (store) => ({
    sidebar: store.sidebar,
    app: store.app,
    auth: store.auth,
    data: store.pages.data,
  }),
  mapDispatchToProps
)(NavbarComponent);
