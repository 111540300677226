import axios from "axios";
import { returnErrors } from "./messages";
import {
  AUTH_ERROR,
  CHECK_VERIFICATION_CODE,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SAVE_FORGOT_PASS,
  USER_LOADED,
  USER_LOADING,
} from "./types";
import { ApiURL } from "../../config";

// CHECK TOKEN AND LOAD USER
export const loadUser = () => async (dispatch, getState) => {
  dispatch({ type: USER_LOADING });
  try {
    let res = await axios.get(`${ApiURL}/auth/user`, tokenConfig(getState));
    if (res.data.isAuthenticated) {
      dispatch({ type: USER_LOADED, payload: res.data });
    } else {
      dispatch({
        type: AUTH_ERROR,
        payload: res.data,
      });
    }
  } catch (err) {}
};
export const login = (username, password) => async (dispatch) => {
  try {
    let res = await axios.post(
      `${ApiURL}/auth/login`,
      { username: username, password: password },
      { withCredentials: true }
    );

    if (res.data.isAuthenticated) {
      dispatch({ type: LOGIN_SUCCESS, payload: res.data });
    } else {
      dispatch({
        type: LOGIN_FAIL,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch(returnErrors(err.response, err.error));
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};
export const logout = () => (dispatch, getState) => {
  axios
    .post(`${ApiURL}/auth/logout`, null, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: LOGOUT_SUCCESS });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
export const forgotPass = (username) => async (dispatch) => {
  try {
    let res = await axios.post(
      `${ApiURL}/auth/forgotPass`,
      { email: username },
      {}
    );
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response, err.error));
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};
export const saveForgotPass =
  (username, verificationCode, password, passwordConfirm) =>
  async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${ApiURL}/auth/saveForgotPass`,
        { email: username, verificationCode, password, passwordConfirm },
        tokenConfig(getState)
      );
      dispatch({
        type: SAVE_FORGOT_PASS,
        payload: res.data,
      });
    } catch (e) {
      console.log("e", e);
    }
  };
export const checkVerificationCode =
  (verificationCode, username) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${ApiURL}/auth/checkVerificationCode`,
        { verificationCode: verificationCode, email: username },
        tokenConfig(getState)
      );
      dispatch({
        type: CHECK_VERIFICATION_CODE,
        payload: res.data,
      });
    } catch (e) {
      console.log("e", e);
    }
  };

export const tokenConfig = (getState) => {
  // GET TOKEN FROM STATE
  const token = getState().auth.token;

  // HEADERS
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  // IF TOKEN ADD TO HEADERS
  if (token) {
    config.headers["Authorization"] = token;
  }
  return config;
};
