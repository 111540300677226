import React, { useEffect } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { archiveSubDataRecord } from "../../client/actions/apiActions";
import { DataListTable } from "./DataListTable";
import { SearchFunction } from "./SearchFunction";
import { SearchFilters } from "./SearchFilters";
import { DataListAddButtons } from "./DataListAddButtons";

const DataList = (props) => {
  const { tableName, title, slogan, organizationId } = props;

  return (
    <Card>
      <CardHeader>
        <div className="row mb-2">
          <div className="col-6">
            <CardTitle tag="h5">{title}</CardTitle>
            <h6 className="card-subtitle text-muted">{slogan}</h6>
          </div>
          {tableName === "users" &&
            props.showOrganizationsLink &&
            organizationId !== "" &&
            typeof organizationId !== "undefined" && (
              <div className="col-6 text-end">
                <Link className="btn btn-dark btn-sm" to="/settings/customers">
                  Back to Organizations
                </Link>
              </div>
            )}
        </div>
        <div className="row align-items-center">
          <DataListAddButtons {...props} />
          {!props.showFilters && <SearchFunction {...props} />}
          {props.showFilters && <SearchFilters {...props} />}
        </div>
      </CardHeader>
      <CardBody>
        <DataListTable
          {...props}
          onArchive={(id) => props.archiveSubDataRecord(tableName, id)}
        />
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  auth: state.user,
  userPermissions: state.auth.userRole,
  data: state.pages.data,
});

export default connect(mapStateToProps, { archiveSubDataRecord })(DataList);
