import React, { useEffect, useState } from "react";
import DataOverview from "../components/data/DataOverview";
import { useLocation, useMatch, useParams } from "react-router-dom";
import Loader from "../components/layouts/Loader";

const tableName = "locations";
const textName = "Locations";
const editPathName = "/locations/:id";
const tableColumns = [
  {
    displayName: "Image",
    dataField: "image",
    type: "image",
    sort: false,
  },
  {
    displayName: "Name",
    dataField: "name",
    sort: true,
  },
  {
    displayName: "Slug",
    dataField: "slug",
    sort: true,
  },
  {
    displayName: "Tools",
    dataField: "tools",
    sort: false,
  },
];
const fieldData = [
  {
    displayName: "Name",
    placeholder: "Name",
    fieldName: "name",
    fieldType: "text",
    colSize: 2,
    required: true,
    requiredMessage: "Field is required",
  },
  {
    displayName: "Slug",
    placeholder: "Slug",
    fieldName: "slug",
    fieldType: "text",
    colSize: 2,
    required: true,
    requiredMessage: "Field is required",
  },
  {
    displayName: "Image",
    placeholder: "Image",
    fieldName: "image",
    fieldType: "image",
    colSize: 1,
    required: false,
  },
  {
    displayName: "Description",
    placeholder: "Description",
    fieldName: "description",
    fieldType: "content",
    colSize: 1,
    required: false,
  },
  {
    displayName: "Page Title",
    placeholder: "title",
    fieldName: "pageTitle",
    fieldType: "text",
    colSize: 1,
    required: true,
    requiredMessage: "Field is required",
  },
  {
    displayName: "Page Content",
    placeholder: "",
    fieldName: "pageDescription",
    fieldType: "content",
    colSize: 1,
    required: false,
  },
];
const fieldDataDefault = {
  name: "",
  image: "",
  slug: "",
  description: "",
};

const Locations = () => {
  const { id } = useParams();
  const location = useLocation();
  const match = useMatch(editPathName);
  const [loading, setLoading] = useState(true);
  let parentRoute = location.pathname;

  useEffect(() => {
    setLoading(false);
  }, []);

  if (match) {
    parentRoute = match.pattern.path.replace("/:id", "");
  }

  if (loading) return <Loader />;
  return (
    <DataOverview
      id={id ?? ""}
      tableName={tableName}
      pageMode={id ? "edit" : "list"}
      route={location.pathname}
      parentRoute={parentRoute}
      pageTitle={textName}
      listTitle={textName}
      listSubTitle={""}
      addTitle={"Add "}
      addSubTitle={""}
      editTitle={`Edit ${textName}`}
      editSubTitle={""}
      tableColumns={tableColumns}
      fieldData={fieldData}
      fieldDataDefault={fieldDataDefault}
    />
  );
};

export default Locations;
