import React, { useEffect, useState } from "react";
import DataOverview from "../components/data/DataOverview";
import { useLocation, useMatch, useParams } from "react-router-dom";
import Loader from "../components/layouts/Loader";

const title = "Discount Codes";
const tableName = "discountCodes";
const editPathName = "/discount-codes/:id";
const tableColumns = [
  {
    dataField: "name",
    text: "Name",
    displayName: "Name",
    sort: true,
  },
  {
    dataField: "code",
    text: "Code",
    displayName: "Code",
    sort: true,
  },
  {
    dataField: "status",
    displayName: "Status",
    type: "bool",
    sort: true,
  },
  {
    dataField: "used",
    displayName: "Used",
    type: "bool",
    sort: true,
  },
  {
    dataField: "oneTimeUse",
    displayName: "One Time",
    type: "bool",
    sort: true,
  },
  {
    dataField: "discountType",
    displayName: "Discount Type",
    type: "text",
    sort: true,
  },
  {
    dataField: "discountAmount",
    displayName: "Discount Amount",
    type: "text",
    sort: true,
  },
  {
    dataField: "duration",
    displayName: "Duration",
    type: "text",
    sort: true,
  },
  {
    dataField: "tools",
    displayName: "Tools",
    text: "Tools",
    sort: false,
  },
];
const fieldData = [
  {
    fieldName: "name",
    displayName: "Name",
    placeholder: "Name",
    fieldType: "text",
    colSize: 3,
    required: true,
    requiredMessage: "Field is required!",
  },
  {
    fieldName: "code",
    displayName: "Code",
    placeholder: "Code",
    fieldType: "text",
    colSize: 3,
    required: true,
    requiredMessage: "Field is required!",
  },
  {
    fieldName: "status",
    displayName: "Status",
    placeholder: "Status",
    fieldType: "toggle",
    colSize: 3,
    required: false,
    requiredMessage: "Field is required!",
  },
  {
    fieldName: "used",
    displayName: "Used",
    placeholder: "Used",
    fieldType: "toggle",
    colSize: 3,
    required: false,
    requiredMessage: "Field is required!",
  },
  {
    fieldName: "discountType",
    displayName: "Discount Type",
    placeholder: "Discount Type",
    fieldType: "text",
    colSize: 3,
    required: true,
    requiredMessage: "Field is required!",
  },
  {
    fieldName: "discountAmount",
    displayName: "discountAmount",
    placeholder: "discountAmount",
    fieldType: "text",
    colSize: 3,
    required: true,
    requiredMessage: "Field is required!",
  },
  {
    fieldName: "oneTimeUse",
    displayName: "One Time Use",
    placeholder: "One Time Use",
    fieldType: "toggle",
    colSize: 3,
    required: false,
    requiredMessage: "Field is required!",
  },
  {
    fieldName: "duration",
    displayName: "Duration",
    placeholder: "Duration",
    fieldType: "number",
    colSize: 3,
    required: false,
    requiredMessage: "Field is required!",
  },
];
const fieldDataDefault = {
  name: "",
  sort: "",
  price: "",
  status: true,
};

const DiscountCodes = () => {
  const { id } = useParams();
  const location = useLocation();
  const match = useMatch(editPathName);
  const [loading, setLoading] = useState(true);
  let parentRoute = location.pathname;
  useEffect(() => {
    setLoading(false);
  }, []);

  if (match) {
    parentRoute = match.pattern.path.replace("/:id", "");
  }

  if (loading) return <Loader />;
  return (
    <DataOverview
      id={id ?? ""}
      tableName={tableName}
      pageMode={id ? "edit" : "list"}
      route={location.pathname}
      parentRoute={parentRoute}
      pageTitle={title}
      listTitle={title}
      listSubTitle={""}
      addTitle={"Add "}
      addSubTitle={""}
      editTitle={`Edit ${title}`}
      editSubTitle={""}
      tableColumns={tableColumns}
      fieldData={fieldData}
      fieldDataDefault={fieldDataDefault}
    />
  );
};

export default DiscountCodes;
