import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../client/actions/auth";
import Loader from "../../components/layouts/Loader";

const SignOut = (props) => {
  const [isAuthenticated] = useState(props?.isAuthenticated);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      props.logout();
    } else {
      navigate("/auth/sign-in");
    }
  }, [props, isAuthenticated, navigate]);

  return <Loader />;
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth.user,
});

export default connect(mapStateToProps, { logout })(SignOut);
