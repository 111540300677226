import React from 'react';

const FormInputHidden = ({name, value, placeholder, onChange}) => {
    return (<input
        id={name}
        name={name}
        type="hidden"
        value={value}
        onChange={onChange}
        aria-describedby={name+"Help"}
        placeholder={placeholder}
    />);
};
export default FormInputHidden
