import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DataList from "./DataList";
import DataEdit from "./DataEdit";
import {
  archiveRecord,
  deleteRecord,
  exportOverview,
  getRecord,
  getTable,
  inviteUser,
  saveRecord,
  testEmail,
} from "../../client/actions/apiActions";
import { connect } from "react-redux";
import Loader from "../layouts/Loader";
import { confirmAlert } from "react-confirm-alert";
import { ApiURL } from "../../config";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "./Modal";
import { FormInput } from "cmation-formsbuilder";

const DataOverview = (props) => {
  const navigate = useNavigate();

  const [pageMode, setPageMode] = useState(props.pageMode ?? "loading");
  const [savePageMode, setSavePageMode] = useState(props.savePageMode ?? "");
  const [pageTitle] = useState("");
  const [singleEdit] = useState(props.singleEdit ?? false);
  const [addTitle] = useState(props.addTitle ?? "");
  const [listTitle] = useState(props.listTitle ?? "");
  const [listSubTitle] = useState(props.listSubTitle ?? "");
  const [tableName] = useState(props.tableName);
  const [tableColumns] = useState(props.tableColumns ?? []);
  const [fieldData] = useState(props.fieldData ?? []);
  const [fieldDataErrors, setFieldDataErrors] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchText, setSearchText] = useState(props.searchText ?? "");
  const [searchColumns, setSearchColumns] = useState(props.searchColumns ?? []);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(
    props.recordsPerPage ?? 25
  );
  const [sortDirection, setSortDirection] = useState(
    props.sortDirection ?? "ASC"
  );
  const [sortFieldName, setSortFieldName] = useState(props.sortFieldName ?? "");
  const [settings] = useState(props.settings ?? {});
  const [organizationId] = useState(props.organizationId ?? "");
  const [showFilters, setShowFilters] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [filters, setFilters] = useState([]);
  const { id } = useParams();

  // MODAL
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalError, setModalError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [inviteEmailAddress, setInviteEmailAddress] = useState("");
  const [userRole, setUserRole] = useState("");

  const refreshFunction = (
    clickedCurrentPage = 0,
    clickedRecordsPerPage = "",
    clickedSortFieldName = "",
    clickedSortDirection = "",
    clickedSearchText = "",
    clickedSearchColumns = "",
    clickedFilters = ""
  ) => {
    loadData(
      clickedCurrentPage,
      clickedRecordsPerPage,
      clickedSortFieldName,
      clickedSortDirection,
      clickedSearchText,
      clickedSearchColumns,
      clickedFilters
    );
  };

  const loadData = (
    clickedCurrentPage = 0,
    clickedRecordsPerPage = "",
    clickedSortFieldName = "",
    clickedSortDirection = "",
    clickedSearchText = "",
    clickedSearchColumns = "",
    clickedFilters = ""
  ) => {
    props.getTable(
      tableName,
      clickedCurrentPage ? clickedCurrentPage : currentPage,
      clickedRecordsPerPage ? clickedRecordsPerPage : recordsPerPage,
      clickedSortFieldName ? clickedSortFieldName : sortFieldName,
      clickedSortDirection ? clickedSortDirection : sortDirection,
      clickedSearchText ? clickedSearchText : searchText,
      organizationId,
      clickedSearchColumns ? clickedSearchColumns : searchColumns,
      clickedFilters ? clickedFilters : filters
    );
  };

  const addFunction = () => {
    setPageMode("loading");
    props.getRecord(tableName, "0");
  };

  const editFunction = (clickedId) => {
    setPageMode("loading");
    props.getRecord(tableName, clickedId);
  };

  const deleteFunction = (id) => {
    props.deleteRecord(tableName, id);
  };

  const archiveRecord = (id) => {
    props.archiveRecord(tableName, id);
  };

  const updatePage = (clickedCurrentPage) => {
    setCurrentPage(clickedCurrentPage);
    refreshFunction(clickedCurrentPage, recordsPerPage);
  };

  const deleteConfirmFunction = (id) => {
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you wish to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteFunction(id);
            setPageMode("loading");
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const testEmail = () => {
    props.testEmail();
  };

  const saveFunction = (saveId, saveMethod, data) => {
    let prevPageMode = pageMode;
    setPageMode("loading");

    let errors = 0;

    let fieldDataErrors = [];
    if (organizationId && tableName === "users") {
      data["organizationId"] = organizationId;
    }
    fieldData.map((field) => {
      if (
        field.required === true &&
        (data[field.fieldName] === "" ||
          typeof data[field.fieldName] === "undefined")
      ) {
        errors = 1;
        let errorMessage = field.requiredMessage;
        fieldDataErrors[field.fieldName] = errorMessage;
        toast.error(errorMessage, {
          theme: "colored",
        });
      }
      return null;
    });

    if (errors === 1) {
      setPageMode(prevPageMode);
      setFieldDataErrors(fieldDataErrors);
    } else {
      if (saveMethod === "save") {
        setPageMode("edit");
        setSavePageMode("edit");
      }

      if (saveMethod === "save and close") {
        setSavePageMode("close");
      }

      if (saveMethod === "save and duplicate") {
        setSavePageMode("duplicate");
      }

      if (saveMethod === "clone") {
        setSavePageMode("edit");
      }
      props.saveRecord(tableName, saveId, data);
      setFieldDataErrors([]);
    }
  };

  const exportFunction = () => {
    props.exportOverview(tableName);
  };

  const resetSearch = () => {
    setSearchText("");
    setPageMode("loading");
    refreshFunction(
      currentPage,
      recordsPerPage,
      sortFieldName,
      sortDirection,
      searchText
    );
  };

  const searchOverview = () => {
    setPageMode("loading");
    refreshFunction();
  };

  const searchKeyPress = (e) => {
    if (e.key === "Enter") {
      searchOverview();
    }
  };

  const closeFunction = () => {
    setPageMode("loading");
    setFieldDataErrors([]);
    refreshFunction();
  };

  const onViewsUsers = (organizationId) => {
    navigate("/organizations/users/" + organizationId);
  };

  const changeSort = (clickedSortFieldName, clickedSortDirection) => {
    if (clickedSortDirection === "DESC") {
      clickedSortDirection = "ASC";
    } else {
      clickedSortDirection = "DESC";
    }

    setPageMode("loading");
    setSortFieldName(clickedSortFieldName);
    setSortDirection(clickedSortDirection);

    refreshFunction(
      currentPage,
      recordsPerPage,
      clickedSortFieldName,
      clickedSortDirection
    );
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const updateFilter = (newFilter) => {
    setFilters(newFilter);
    refreshFunction(
      currentPage,
      recordsPerPage,
      sortFieldName,
      sortDirection,
      searchText,
      searchColumns,
      newFilter
    );
  };

  const changeRecordsPerPage = (e) => {
    setCurrentPage(1);
    // this.setState({ [e.target.name]: e.target.value, currentPage: 1 });
    refreshFunction(1, e.target.value);
  };

  const onSaveModal = () => {
    if (modalType === "inviteUser") {
      if (validateEmailAddress(inviteEmailAddress) && firstName && lastName) {
        props.inviteUser(inviteEmailAddress, firstName, lastName);
      } else {
        setModalError("All fields are required, and a valid email address");
      }
    }
  };

  const onCloseModal = () => {
    setShowModal(false);
    setModalType("");
    setModalTitle("");
    setInviteEmailAddress("");
    setFirstName("");
    setLastName("");
    setModalError("");
    setUserRole("");
    refreshFunction();
  };

  const validateEmailAddress = (emailAddress) => {
    let validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (emailAddress.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  };

  const inviteFunction = () => {
    setShowModal(true);
    setModalType("inviteUser");
    setModalTitle("Invite User to Simply Collaborate");
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (singleEdit === true) {
      editFunction(id ?? props.id);
    } else if (id && typeof id !== "undefined") {
      if (id === "0") {
        setPageMode("add");
      } else {
        setPageMode("edit");
      }
      editFunction(id);
    } else if (singleEdit === false) {
      if (props.data.dataType === "saveRecord") {
        toast.success("Record saved successfully!", {
          theme: "colored",
        });
      }
      setPageMode("loading");
      refreshFunction();
    }
  }, [props.id]);

  useEffect(() => {
    console.log("useEffect 2 dataType:", props.data.dataType);
    console.log("useEffect 2 pageMode:", pageMode);
    console.log("useEffect 2 savePageMode:", savePageMode);

    if (pageMode === "loading") {
      if (props.data.dataType === "getTable") {
        setPageMode("list");
        return;
      }
      if (props.data.dataType === "addRecord") {
        setPageMode("add");
        return;
      }
      if (props.data.dataType === "getRecord") {
        setPageMode("edit");
        return;
      }
      if (props.data.dataType === "saveRecord") {
        if (!singleEdit && savePageMode === "close") {
          navigate(props.parentRoute);
        } else {
          toast.success("Record saved successfully!", {
            theme: "colored",
          });
        }
        return;
      }
      if (props.data.dataType === "deleteRecord") {
        setPageMode("list");
        refreshFunction();
        return;
      }
      if (
        props.data.dataType === "export" &&
        props.data.status === 1 &&
        props.data.code !== "" &&
        typeof props.data.code !== "undefined"
      ) {
        window.open(ApiURL + "/export/" + props.data.code);
      }
    } else {
      if (props.data.dataType === "saveRecord") {
        toast.success("Record saved successfully!", {
          theme: "colored",
        });
      }
    }
    if (props.data.dataType === "sendUserInvite") {
      if (props.data.status === true) {
        onCloseModal();
        refreshFunction();
        toast.success("Invite was sent successfully!", {
          theme: "colored",
        });
      } else {
        setModalError(props.data.message);
      }
    }
    if (props.data.dataType === "resendInvite") {
      onCloseModal();
      if (props.data.status === true) {
        toast.success("Invite was sent successfully!", {
          theme: "colored",
        });
      } else {
        toast.warn("Error sending invite.", {
          theme: "colored",
        });
      }
    }
  }, [props.data.dataType, props.data.timestamp]);

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">{pageTitle}</h1>
      {pageMode === "loading" && <Loader />}
      {pageMode === "list" && (
        <DataList
          route={props.route}
          parentRoute={props.parentRoute}
          showOrganizationsLink={props.showOrganizationsLink}
          title={listTitle}
          addTitle={addTitle}
          slogan={listSubTitle}
          columns={tableColumns}
          tableData={props.data.dataValues ?? []}
          tableName={tableName}
          sortFieldName={sortFieldName}
          sortDirection={sortDirection}
          searchText={searchText}
          settings={settings}
          currentPage={currentPage}
          totalPages={totalPages}
          totalRecords={totalRecords}
          recordsPerPage={recordsPerPage}
          organizationId={organizationId}
          showFilters={showFilters}
          filters={filters}
          filterText={filterText}
          updateFilter={updateFilter}
          resetSearch={resetSearch}
          onViewsUsers={onViewsUsers}
          searchOverview={searchOverview}
          searchKeyPress={searchKeyPress}
          onUpdatePage={updatePage}
          onAdd={addFunction}
          onInvite={inviteFunction}
          onExport={exportFunction}
          toggleFilters={toggleFilters}
          onChangeRecordsPerPage={changeRecordsPerPage}
          changeSort={changeSort}
          onEdit={editFunction}
          onDelete={deleteConfirmFunction}
          onArchive={archiveRecord}
        />
      )}
      {(pageMode === "add" || pageMode === "view" || pageMode === "edit") && (
        <DataEdit
          route={props.route}
          parentRoute={props.parentRoute}
          mode={pageMode}
          singleEdit={singleEdit}
          title={props[pageMode + "Title"]}
          slogan={props[pageMode + "SubTitle"]}
          tableName={tableName}
          organizationId={organizationId}
          settings={settings}
          dataFields={fieldData}
          dataResults={props.data.dataValues ?? []}
          dataErrors={fieldDataErrors}
          onArchive={archiveRecord}
          onSave={saveFunction}
          onCancel={closeFunction}
          testEmail={testEmail}
        />
      )}
      <ToastContainer />

      {showModal && (
        <Modal
          handleClose={onCloseModal}
          show={showModal}
          title={modalTitle}
          onSave={onSaveModal}
          modalType={modalType}
        >
          {modalType === "inviteUser" && (
            <div className="container">
              {modalError && (
                <div className="row">
                  <div className="col-12">
                    <div className="alert alert-danger text-dark py-1 px-4">
                      {modalError}
                    </div>
                  </div>
                </div>
              )}

              <FormInput
                colSize={1}
                label="First Name"
                type="text"
                name="firstName"
                value={firstName}
                onChange={(value) => setFirstName(value)}
              />
              <FormInput
                colSize={1}
                label="Last Name"
                type="text"
                name="lastName"
                value={lastName}
                onChange={(value) => setLastName(value)}
              />
              <FormInput
                colSize={1}
                label="Invite Users Email Address"
                type="text"
                name="inviteEmailAddress"
                value={inviteEmailAddress}
                onChange={(value) => setInviteEmailAddress(value)}
              />
            </div>
          )}
        </Modal>
      )}
    </Container>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth.user,
  data: state.pages.data,
});

export default connect(mapStateToProps, {
  getTable,
  getRecord,
  saveRecord,
  archiveRecord,
  testEmail,
  inviteUser,
  deleteRecord,
  exportOverview,
})(DataOverview);
