import { GET_DATA, USER_LOADED } from "./types";
import axios from "axios";

import { ApiURL } from "../../config";

export const exportOverview = (tableName) => async (dispatch, getState) => {
  try {
    let res = await axios.post(
      `${ApiURL}/export/overview`,
      { tableName },
      tokenConfig(getState)
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};
export const getConfig = () => async (dispatch, getState) => {
  try {
    let res = await axios.get(`${ApiURL}/getConfig`, tokenConfig(getState));
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};
export const getList =
  (tableName, extraTables) => async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/getList`,
        { tableName, extraTables },
        tokenConfig(getState)
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };
export const getAccounts = () => async (dispatch, getState) => {
  try {
    let res = await axios.get(`${ApiURL}/getAccounts`, tokenConfig(getState));
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};
export const getAccount = (accountId) => async (dispatch, getState) => {
  try {
    let res = await axios.post(
      `${ApiURL}/getAccount`,
      { accountId },
      tokenConfig(getState)
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};
export const updateCreditCard =
  (nonce, customerId, cardId) => async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/updateCreditCard`,
        { nonce, customerId, cardId },
        tokenConfig(getState)
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };
export const cancelSubscription =
  (paymentSubscriptionId) => async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/cancelSubscription`,
        { paymentSubscriptionId },
        tokenConfig(getState)
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };
export const restartSubscription =
  (paymentSubscriptionId) => async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/restartSubscription`,
        { paymentSubscriptionId },
        tokenConfig(getState)
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };

export const deleteSubscriptionAction =
  (paymentSubscriptionId, actionId) => async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/deleteSubscriptionAction`,
        { paymentSubscriptionId, actionId },
        tokenConfig(getState)
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };

export const setupDatabase = (accountId) => async (dispatch, getState) => {
  try {
    let res = await axios.post(
      `${ApiURL}/setupDatabase`,
      { accountId },
      tokenConfig(getState)
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};

export const setupDomain =
  (accountId, domain, companyName) => async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/setupDomain`,
        { accountId, domain, companyName },
        tokenConfig(getState)
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };

export const getSubscription =
  (subscriptionId) => async (dispatch, getState) => {
    try {
      let res = await axios.get(
        `${ApiURL}/getSubscription/${subscriptionId}`,
        tokenConfig(getState)
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };

export const testEmail = () => async (dispatch, getState) => {
  try {
    let res = await axios.get(`${ApiURL}/test-mail`, tokenConfig(getState));
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};
export const getUserRoles = (organizationId) => async (dispatch, getState) => {
  try {
    let res = await axios.post(
      `${ApiURL}/getUserRoles`,
      { organizationId },
      tokenConfig(getState)
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};

export const getLinkedTable =
  (linkedTableName, linkedTableInfo) => async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/getLinkedTable`,
        { linkedTableName, linkedTableInfo },
        tokenConfig(getState)
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };
export const archiveSubDataRecord =
    (tableName, tableId, activeStatus) => async (dispatch, getState) => {
      try {
        let res = await axios.post(
            `${ApiURL}/archiveRecord`,
            { tableName, tableId, activeStatus },
            tokenConfig(getState)
        );
        dispatch({
          type: GET_DATA,
          payload: res.data,
        });
      } catch (err) {
        console.log("logAction: ", err);
      }
    };


export const loadInvite = (inviteId) => async (dispatch, getState) => {
  try {
    let res = await axios.post(
        `${ApiURL}/loadInvite`,
        {
          inviteId,
        },
        tokenConfig(getState)
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};

export const getTable =
  (
    tableName,
    currentPage,
    recordsPerPage,
    sortFieldName,
    sortDirection,
    searchText,
    organizationId
  ) =>
  async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/getTable`,
        {
          tableName,
          currentPage,
          recordsPerPage,
          sortFieldName,
          sortDirection,
          searchText,
          organizationId,
        },
        tokenConfig(getState)
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };
export const getContent = (contentPage) => async (dispatch, getState) => {
  try {
    let res = await axios.post(
      `${ApiURL}/getContent`,
      { contentPage },
      tokenConfig(getState)
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};
export const getRecord = (tableName, tableId) => async (dispatch, getState) => {
  try {
    let res = await axios.post(
      `${ApiURL}/getRecord`,
      { tableName, tableId },
      tokenConfig(getState)
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};

export const deleteRecord =
  (tableName, tableId) => async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/deleteRecord`,
        { tableName, tableId },
        tokenConfig(getState)
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };
export const archiveRecord =
  (tableName, tableId, activeStatus) => async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/archiveRecord`,
        { tableName, tableId, activeStatus },
        tokenConfig(getState)
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };
export const inviteUser =
  ( emailAddress, firstName, lastName) =>
  async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/sendUserInvite`,
        {
          firstName: firstName,
          lastName: lastName,
          inviteEmail: emailAddress,
        },
        tokenConfig(getState)
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };
export const saveAccount =
  (
    inviteId,
    firstName,
    lastName,
    email,
    password,
    passwordConfirm,
    cellPhone,
    workPhone
  ) =>
  async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/saveAccount`,
        {
          inviteId,
          firstName,
          lastName,
          email,
          password,
          passwordConfirm,
          cellPhone,
          workPhone,
        },
        tokenConfig(getState)
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };

export const saveRecord =
  (tableName, tableId, data) => async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/saveRecord`,
        { tableName, tableId, data },
        tokenConfig(getState)
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };

export const loadDashboard = () => async (dispatch, getState) => {
  try {
    let res = await axios.get(`${ApiURL}/loadDashboard`, tokenConfig(getState));
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};

export const getProfileInfo = () => async (dispatch, getState) => {
  try {
    let res = await axios.get(
      `${ApiURL}/getProfileInfo`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};

export const getNotifications = () => async (dispatch, getState) => {
  try {
    let res = await axios.get(
      `${ApiURL}/getNotifications`,
      tokenConfig(getState)
    );
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};
export const getNotificationList = () => async (dispatch, getState) => {
  try {
    let res = await axios.get(
      `${ApiURL}/getNotificationList`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};

export const markNotificationRead =
  (notificationId) => async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/markNotificationRead`,
        { notificationId },
        tokenConfig(getState)
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };

export const tokenConfig = (getState) => {
  // GET TOKEN FROM STATE
  const token = getState().auth.token;

  // HEADERS
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  // IF TOKEN ADD TO HEADERS
  if (token) {
    config.headers["Authorization"] = token;
  }
  return config;
};
