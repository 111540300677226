import React, { Component } from "react";
import DataOverview from "./data/DataOverview";
import { connect } from "react-redux";
import { getProfileInfo } from "../client/actions/apiActions";
import Loader from "./layouts/Loader";
import ProfileLinks from "./ProfileLinks";
import { Navigate } from "react-router-dom";

const tableName = "usersPassword";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewMode: "data",
      fieldData: [
        {
          fieldName: "password",
          fieldType: "password",
          colSize: 2,
          required: true,
        },
        {
          fieldName: "passwordConfirm",
          fieldType: "password",
          colSize: 2,
          required: true,
        },
      ],
      fieldDataResults: { password: "", passwordConfirm: "", viewMode: "data" },
      logout: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      console.log("Password Props", this.props.data.status);
      if (this.props.data.status === 1) {
        this.setState({ logout: true });
      }
    }
  }

  render() {
    const { fieldDataResults, viewMode, fieldData, fieldDataDefault } =
      this.state;
    if (this.state.logout === true) {
      return <Navigate to="/auth/sign-out" />;
    } else if (viewMode === "loading") {
      return <Loader />;
    } else {
      return (
        <div className="container p-0">
          <div className="row">
            <div className="col-md-3 col-2">
              <ProfileLinks linkActive="/profile-password" />
            </div>
            <div className="col-md-9 col-10">
              <DataOverview
                singleEdit={true}
                tableName={tableName}
                fieldData={fieldData}
                fieldDataResults={fieldDataResults}
                settings={{ showClone: false }}
                languageTable={tableName}
                pageTitle={"Password"}
                listTitle={"Password"}
                listSubTitle={""}
                addTitle={""}
                addSubTitle={""}
                editTitle={"Edit Password"}
                editSubTitle={""}
                fieldDataDefault={fieldDataDefault}
              />
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.user,
  data: state.pages.data,
});

export default connect(mapStateToProps, { getProfileInfo })(Profile);
