import React, {Component, Fragment} from 'react';
import FormInputCheckboxElement from './FormInputCheckboxElement';
import classnames from "classnames";
import ShowInputError from "./ShowInputError";

class FormInputCheckbox extends Component {
    render () {
        const { name, value, label, colSize, helpText, valueOptions, selectedCheckboxes, handleCheckboxChange, error } = this.props;
        let divColSize;
        let colLength1;
        let colLength2;

        if (colSize === 0) { divColSize = 0; colLength1 = "col-sm-4"; colLength2 = "col-sm-8"; }
        if (colSize === 1) { divColSize = 12; colLength1 = "col-sm-12"; colLength2 = "col-sm-12"; }
        if (colSize === 2) { divColSize = 6; colLength1 = "col-sm-4"; colLength2 = "col-sm-8"; }
        if (colSize === 3) { divColSize = 4; colLength1 = "col-sm-4"; colLength2 = "col-sm-8"; }
        if (colSize === 4) { divColSize = 8; colLength1 = "col-sm-4"; colLength2 = "col-sm-8"; }
        let optionsValue = '';
        if (typeof(value) !== 'undefined') optionsValue = value.toString();

        return (
            <div className={`col-${divColSize}`}>
                <div className="container p-0">
                    <div className="form-group row border-bottom pb-2">
                        <label htmlFor={name} className={colLength1}>{label}</label>
                        <div className={colLength2}>
                            {valueOptions ? (
                                valueOptions.map((options, index) => {
                                    let dataChecked = false;
                                    if (typeof(options.id) !== 'undefined' && optionsValue.indexOf("," + options.id.toString() + ",") !== -1) {
                                        dataChecked = true;
                                    }
                                    if (typeof(options.code) !== 'undefined' && optionsValue.indexOf("," + options.code.toString() + ",") !== -1) {
                                        dataChecked = true;
                                    }
                                    if (optionsValue === options.id) {
                                        dataChecked = true;
                                    }
                                    return (<div key={index} className="form-check form-check-inline px-3">
                                        <label className="form-check-label" htmlFor={name + index}>
                                            {typeof(options.id) !== 'undefined' && <FormInputCheckboxElement
                                                className={classnames('form-check-input', { 'is-invalid': error})}
                                                type="checkbox"
                                                checked={dataChecked}
                                                name={name}
                                                id={name + index}
                                                value={options.id}
                                                selectedCheckboxes={selectedCheckboxes}
                                                handleCheckboxChange={handleCheckboxChange}
                                            />}
                                            {typeof(options.code) !== 'undefined' && <FormInputCheckboxElement
                                                className={classnames('form-check-input', { 'is-invalid': error})}
                                                type="checkbox"
                                                checked={dataChecked}
                                                name={name}
                                                id={name + index}
                                                value={options.code}
                                                selectedCheckboxes={selectedCheckboxes}
                                                handleCheckboxChange={handleCheckboxChange}
                                            />}
                                            {options.name}
                                        </label>
                                    </div>);
                                }))
                            : null}
                        </div>
                        {helpText && <Fragment><div className="col-4">&nbsp;</div><small id={name + "Help"} className="col-8 form-text text-muted">{helpText}</small></Fragment>}
                        {error && <ShowInputError error={error} />}
                    </div>
                </div>
            </div>
        )
    }
}
export default FormInputCheckbox
