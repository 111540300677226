import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getContent } from "../client/actions/apiActions";
import Loader from "./layouts/Loader";
import { useResolvedPath } from "react-router-dom";

const ContentPage = (props) => {
  const pathName = useResolvedPath(window.location);

  const [pageTitle, setPageTitle] = useState();
  const [pageHTML, setPageHTML] = useState();
  const [viewMode, setViewMode] = useState("loading");

  useEffect(() => {
    setPageTitle(props.data.pageTitle);
    setPageHTML(props.data.pageHTML);
    setViewMode("data");
  }, [props]);

  useEffect(() => {
    props.getContent(pathName.pathname);
  }, [pathName]);

  if (viewMode === "loading") {
    return <Loader />;
  }

  return (
    <div className="container p-0">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">{pageTitle}</h5>
              <div dangerouslySetInnerHTML={{ __html: pageHTML }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth.user,
  data: state.pages.data,
});

export default connect(mapStateToProps, { getContent })(ContentPage);
