import { DataListPages } from "./DataListPages";
import { DataListColumns } from "./DataListColumns";
import { DataListData } from "./DataListData";
import React from "react";

export const DataListTable = ({
  columns,
  currentPage,
  totalPages,
  onUpdatePage,
  recordsPerPage,
  sortDirection,
  sortFieldName,
  hasStatus,
  tableData,
  tableName,
  onEdit,
  onDelete,
  onArchive,
  changeSort,
  onChangeRecordsPerPage,
  totalRecords,
  onViewsUsers,
  userPermissions,
  route,
  parentRoute,
  onResendInvite,
}) => {
  return (
    <table className="table table-hover table-striped table-sm m-0 bg-white">
      <thead>
        <DataListPages
          columns={columns}
          currentPage={currentPage}
          totalPages={totalPages}
          onUpdatePage={onUpdatePage}
          recordsPerPage={recordsPerPage}
          onChangeRecordsPerPage={onChangeRecordsPerPage}
        />
      </thead>
      <thead>
        {columns && (
          <DataListColumns
            columns={columns}
            sortDirection={sortDirection}
            sortFieldName={sortFieldName}
            changeSort={changeSort}
            hasStatus={hasStatus}
            tableName={tableName}
          />
        )}
      </thead>
      <tbody>
        {tableData && (
          <DataListData
            route={route}
            parentRoute={parentRoute}
            tableData={tableData}
            columns={columns}
            tableName={tableName}
            onViewsUsers={onViewsUsers}
            onResendInvite={onResendInvite}
            onEdit={onEdit}
            onDelete={onDelete}
            hasStatus={hasStatus}
            onArchive={onArchive}
            userPermissions={userPermissions}
          />
        )}
      </tbody>
      <tfoot>
        <DataListPages
          columns={columns}
          currentPage={currentPage}
          totalPages={totalPages}
          onUpdatePage={onUpdatePage}
          recordsPerPage={recordsPerPage}
          onChangeRecordsPerPage={onChangeRecordsPerPage}
        />
        <tr>
          <td colSpan={columns.length}>
            Total Pages : {totalPages} - Total Records : {totalRecords}
          </td>
        </tr>
      </tfoot>
    </table>
  );
};
