import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAccounts } from "../client/actions/apiActions";
import Account from "./Account/Account";
import Loader from "../components/layouts/Loader";

const Accounts = (props) => {
  const { data } = props;
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [pendingAccounts, setPendingAccounts] = useState([]);

  useEffect(() => {
    if (data.accounts && data.accounts !== accounts) {
      setAccounts(data.accounts);
      setSubscriptionTypes(data.subscriptionTypes);
      setLoading(false);
    }
    if (data.pendingAccounts && data.pendingAccounts !== pendingAccounts) {
      setPendingAccounts(data.pendingAccounts);
    }
  }, [data]);

  useEffect(() => {
    props.getAccounts();
  }, []);

  if (loading) return <Loader />;

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3>Pending Setup / Approval</h3>
            <table className="table table-sm table-hover">
              <thead>
                <tr>
                  <td>Workspace</td>
                  <td>Company</td>
                  <td>Contact</td>
                  <td>Subscription</td>
                  <td>Status</td>
                  <td>Tools</td>
                </tr>
              </thead>
              <tbody>
                {pendingAccounts.map((account) => {
                  return (
                    <Account
                      key={account.id}
                      account={account}
                      subscriptionTypes={subscriptionTypes}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h3>Clients</h3>
            <table className="table table-sm table-hover">
              <thead>
                <tr>
                  <td>Workspace</td>
                  <td>Company</td>
                  <td>Contact</td>
                  <td>Subscription</td>
                  <td>Status</td>
                  <td>Tools</td>
                </tr>
              </thead>
              <tbody>
                {accounts.map((account) => {
                  return (
                    <Account
                      account={account}
                      subscriptionTypes={subscriptionTypes}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.pages.data,
});

export default connect(mapStateToProps, { getAccounts })(Accounts);
