import React, { Component } from "react";
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from "reactstrap";
import Loader from "../layouts/Loader";
import { getUserRoles } from "../../client/actions/apiActions";
import { connect } from "react-redux";
import DataEditButtons from "./DataEditButtons";
import FormInput from "../form/FormInput";

class DataEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFields: [],
      dataResults: [],
      loading: true,
    };
    this.selectedCheckboxes = new Set();
  }

  componentDidMount() {
    let dataResults = this.props.dataResults;

    if (typeof dataResults?.languageList !== "undefined") {
      let languageList = dataResults.languageList.split(",");
      languageList.map((options, index) => {
        if (options !== "" && typeof options !== "undefined") {
          this.selectedCheckboxes.add(options);
        }
        return { ...options };
      });
    }

    if (
      typeof dataResults?.category !== "undefined" &&
      this.props.tableName === "toolbox"
    ) {
      let category = dataResults.category.split(",");
      if (typeof this.selectedCheckboxes["category"] === "undefined") {
        this.selectedCheckboxes["category"] = new Set();
      }
      category.map((options) => {
        if (options !== "" && typeof options !== "undefined") {
          this.selectedCheckboxes["category"].add(options);
        }
        return { ...options };
      });
    }

    if (
      typeof dataResults?.userRole !== "undefined" &&
      this.props.tableName === "users"
    ) {
      let userRole = dataResults.userRole.split(",");
      if (typeof this.selectedCheckboxes["userRole"] === "undefined") {
        this.selectedCheckboxes["userRole"] = new Set();
      }
      userRole.map((options, index) => {
        if (options !== "" && typeof options !== "undefined") {
          this.selectedCheckboxes["userRole"].add(options);
        }
        return { ...options };
      });
      this.props.getUserRoles(this.props.organizationId);
    }

    if (
      typeof dataResults?.tablesCanAccess !== "undefined" &&
      this.props.tableName === "userRoles"
    ) {
      let tablesCanAccess = dataResults.tablesCanAccess.split(",");
      if (typeof this.selectedCheckboxes["tablesCanAccess"] === "undefined") {
        this.selectedCheckboxes["tablesCanAccess"] = new Set();
      }
      tablesCanAccess.map((options, index) => {
        if (options !== "" && typeof options !== "undefined") {
          this.selectedCheckboxes["tablesCanAccess"].add(options);
        }
        return { ...options };
      });
    }

    this.setState({
      dataResults: dataResults,
      loading: false,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      if (typeof this.props.data.userRoles !== "undefined") {
        let dataFields = this.props.dataFields;
        dataFields.map((field, index) => {
          if (field.fieldName === "userRole") {
            dataFields[index]["valueOptions"] = this.props.data.userRoles;
          }
          return null;
        });
        this.setState({ dataFields: dataFields });
      }
    }
  }

  onChange = (type, e, field) => {
    let dataResults = this.state.dataResults;
    let tmpValue = "";
    if (type === "text") {
      dataResults[field.fieldName] = e.target.value;
    } else if (type === "phone") {
      dataResults[field.fieldName] = e.target.value;
    } else if (type === "email") {
      dataResults[field.fieldName] = e.target.value;
    } else if (type === "textarea") {
      dataResults[field.fieldName] = e.target.value;
    } else if (type === "toggle") {
      if (e.target.checked === true) dataResults[field.fieldName] = "1";
      if (e.target.checked === false) dataResults[field.fieldName] = "0";
    } else if (type === "image") {
      dataResults[field.fieldName] = e;
    } else if (type === "editor") {
      dataResults[field.fieldName] = e; // e.target.getContent();
    }
    //else if (type === 'editor')
    //{
    //    dataResults[field.fieldName] = e.target.getContent();
    //}
    else if (type === "select" || type === "dropdown") {
      dataResults[e.target.name] = e.target.value;
    } else if (type === "password") {
      dataResults[field.fieldName] = e.target.value;
    } else if (type === "checkbox") {
      dataResults[field.fieldName] = e.target.value;
    } else if (type === "custom") {
      dataResults[field.fieldName] = e;
    } else if (type === "radio") {
      dataResults[field.fieldName] = e.target.value;
    } else {
      dataResults[field.fieldName] = e.target.value;
    }

    if (typeof field !== "undefined") {
      if (typeof field.callBackFunction !== "undefined") {
        console.log("callBackFunction", field.callBackFunction);
        this.props[field.callBackFunction](tmpValue);
      }
    }

    this.setState({ dataResults: dataResults });
  };

  onButtonCancel = () => {
    this.props.onCancel();
    for (const checkbox of this.selectedCheckboxes) {
      if (this.selectedCheckboxes.has(checkbox)) {
        this.selectedCheckboxes.delete(checkbox);
      }
    }
  };

  handleCheckboxChange = (name, label) => {
    if (typeof this.selectedCheckboxes[name] === "undefined")
      this.selectedCheckboxes[name] = new Set();
    if (this.selectedCheckboxes[name].has(label)) {
      this.selectedCheckboxes[name].delete(label);
    } else {
      this.selectedCheckboxes[name].add(label);
    }

    let checkedBoxes = ",";
    for (const checkbox of this.selectedCheckboxes[name]) {
      checkedBoxes += checkbox + ",";
    }
    this.setState({
      dataResults: { ...this.state.dataResults, [name]: checkedBoxes },
    });
  };

  changeValue = (name, value) => {
    let dataResults = this.state.dataResults;
    dataResults[name] = value;
    this.setState({ dataResults: dataResults });
  };

  render() {
    let dataFields = this.state.dataFields;
    if (dataFields.length === 0) {
      dataFields = this.props.dataFields;
    }
    const { dataErrors, title, slogan, settings } = this.props;
    const { loading, dataResults } = this.state;

    if (loading === true) {
      return <Loader />;
    } else {
      return (
        <Card>
          <CardHeader>
            <CardTitle tag="h5">{title}</CardTitle>
            <h6 className="card-subtitle text-muted">{slogan}</h6>
            <DataEditButtons
              {...this.props}
              dataResults={dataResults}
              onButtonCancel={this.onButtonCancel}
            />
          </CardHeader>
          <CardBody>
            <div className="container">
              <div className="row align-items-end">
                {dataFields.map((field, index) => {
                  return (
                    <FormInput
                      id={dataResults.id ?? ""}
                      field={field}
                      type={field.fieldType}
                      name={field.fieldName}
                      defaultValue={field.defaultValue}
                      dropdown={field.dropdownData}
                      options={field.options}
                      valueOptions={field.valueOptions}
                      checkboxData={field.checkboxData}
                      colSize={field.colSize}
                      value={dataResults[field.fieldName]}
                      changeValue={this.changeValue}
                      tableName={this.props.tableName}
                      onChange={this.onChange}
                      error={dataErrors[field.fieldName]}
                      label={field.displayName}
                      handleCheckboxChange={this.handleCheckboxChange}
                      placeholder={field.placeholder}
                    />
                  );
                })}
              </div>
            </div>
          </CardBody>
          <CardFooter>
            <DataEditButtons
              {...this.props}
              dataResults={dataResults}
              onButtonCancel={this.onButtonCancel}
            />
          </CardFooter>
        </Card>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.user,
  data: state.pages.data,
});

export default connect(mapStateToProps, { getUserRoles })(DataEdit);
