import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getConfig } from "../../client/actions/apiActions";
import { login } from "../../client/actions/auth";
import { Card, CardBody } from "reactstrap";
import { FormInput } from "cmation-formsbuilder";

const SignIn = (props) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    makeFocus();
  }, [props]);

  useEffect(() => {
    console.log("SignIn useEffect()", props);
    if (!props.auth?.status) {
      setErrorMessage(props.auth?.details);
      makeFocus();
    } else if (props?.isAuthenticated) {
      navigate("/");
    }
  }, [navigate, props]);

  const makeFocus = () => {
    try {
      window.username.focus();
    } catch {
      // do nothing
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    props.login(username, password);
  };
  console.log("props", props);
  return (
    <Card>
      <CardBody>
        <div className="col-12 text-center">
          <h1 className="my-5">
            Pinellas
            <br />
            Christian Businesses
          </h1>
        </div>
        <div className="m-sm-4">
          <div className="text-center">
            <p className="lead">Sign in to the control panel</p>
            {errorMessage && (
              <div
                className="alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                <div className="alert-message">{errorMessage}</div>
              </div>
            )}
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            <FormInput
              type="email"
              id="username"
              label="Email"
              name="username"
              value={username}
              hideBorder={true}
              floatingLabel={true}
              placeholder="Enter your email"
              autoComplete="username"
              onChange={(value) => setUsername(value)}
            />
            <FormInput
              type="password"
              id="password"
              label="Password"
              name="password"
              floatingLabel={true}
              value={password}
              hideBorder={true}
              hideMeter={true}
              placeholder="Enter your password"
              autoComplete="current-password"
              onChange={(value) => setPassword(value)}
            />
            <small>
              <Link to="/auth/reset-password">Forgot password?</Link>
            </small>
            <div className="row">
              <div className="col-6 mt-3">
                <Link to="/" className="btn btn-secondary">
                  Back
                </Link>
              </div>
              <div className="col-6 text-end mt-3">
                <button className="btn btn-primary" onClick={onSubmit}>
                  <i className="fas fa-unlock-alt" /> Sign in
                </button>
              </div>
            </div>
          </form>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
});

export default connect(mapStateToProps, { login, getConfig })(SignIn);
