import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";

class ShowInputError extends Component {
  render() {
    return (
      <div className="col-12">
        <div className="p-1 alert alert-danger" role="alert">
          <div className="alert-message">
            <FontAwesomeIcon icon={faBell} fixedWidth /> {this.props.error}
          </div>
        </div>
      </div>
    );
  }
}
export default ShowInputError;
