import React, { useEffect, useState } from "react";
import DataOverview from "../components/data/DataOverview";
import { useLocation, useMatch, useParams } from "react-router-dom";
import Loader from "../components/layouts/Loader"; // Import css

const tableName = "emailTemplates";
const editPathName = "/settings/email-templates/:id";
const tableColumns = [
  {
    dataField: "name",
    text: "Template Name",
    displayName: "Template Name",
    sort: true,
  },
  {
    dataField: "emailSubject",
    displayName: "Email Subject",
    text: "Email Subject",
    sort: true,
  },
  {
    dataField: "emailFromName",
    displayName: "From Name",
    text: "From Name",
    sort: true,
  },
  {
    dataField: "emailFromEmail",
    displayName: "From Email",
    text: "From Email",
    sort: true,
  },
  {
    dataField: "tools",
    displayName: "Tools",
    text: "Tools",
    sort: false,
  },
];
const fieldData = [
  {
    fieldName: "name",
    displayName: "Email Template Name",
    placeholder: "email template name",
    fieldType: "text",
    colSize: 2,
    required: true,
    requiredMessage: "Email Template Name is required!",
  },
  {
    fieldName: "emailSubject",
    displayName: "Email Subject",
    placeholder: "",
    fieldType: "text",
    colSize: 2,
  },
  {
    fieldName: "emailFromName",
    displayName: "From Name",
    placeholder: "",
    fieldType: "text",
    colSize: 2,
  },
  {
    fieldName: "emailFromEmail",
    displayName: "From Email",
    placeholder: "",
    fieldType: "email",
    colSize: 2,
  },
  {
    fieldName: "emailMessage",
    displayName: "Email Message",
    placeholder: "",
    fieldType: "editor",
    colSize: 1,
  },
];
const fieldDataDefault = {
  name: "",
  emailFromName: "",
  emailFromEmail: "",
  emailSubject: "",
  emailMessage: "",
};

const EmailTemplates = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const match = useMatch(editPathName);
  const [loading, setLoading] = useState(true);
  let parentRoute = pathname;
  useEffect(() => {
    setLoading(false);
  }, []);

  if (match) {
    parentRoute = match.pattern.path.replace("/:id", "");
  }

  if (loading) return <Loader />;
  return (
    <DataOverview
      id={id ?? ""}
      tableName={tableName}
      pageMode={id ? "edit" : "list"}
      route={pathname}
      sortFieldName={"name"}
      sortDirection={"ASC"}
      parentRoute={parentRoute}
      pageTitle={"Email Templates"}
      listTitle={"Email Templates"}
      listSubTitle={""}
      addTitle={"Add Email Templates"}
      addSubTitle={""}
      editTitle={"Edit Email Templates"}
      editSubTitle={""}
      tableColumns={tableColumns}
      fieldData={fieldData}
      fieldDataDefault={fieldDataDefault}
    />
  );
};

export default EmailTemplates;
