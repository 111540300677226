import { Link } from "react-router-dom";
import { Eye as ViewIcon } from "react-feather";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";

const DashboardNewUsers = (props) => {
  const { newUsers } = props;
  useEffect(() => {}, [props]);

  return (
    <div className="card">
      <div className="card-header">
        <h3>New Users</h3>
      </div>
      <div className="card-body">
        <p>These users have not yet accepted their invites</p>
        {newUsers &&
          newUsers.length > 0 &&
          newUsers.map((user, index) => {
            return (
              <div key={index} className="row border-top text-sm mb-1 py-2">
                <div className="col-9">
                  {user.firstName} &nbsp;
                  {user.lastName} &nbsp; - &nbsp;
                  {user.companyName} &nbsp;
                  {user.email}
                </div>
                <div className="col-3 text-end">
                  <Link
                    to={`/accounts/${user.organizationId}`}
                    className="btn btn-sm btn-primary"
                  >
                    <ViewIcon size={18} />
                  </Link>
                </div>
              </div>
            );
          })}
        {newUsers && newUsers.length === 0 && (
          <div className="row text-sm mb-1">
            <div className="col-12">No new users found</div>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth.user,
  data: state.pages.data,
});

export default connect(mapStateToProps, {})(DashboardNewUsers);
