import React, {Component, Fragment} from 'react';
import {Editor} from '@tinymce/tinymce-react';

class FormInputSimpleEditor extends Component {
    render() {
        const {field, name, value, label, onChange, colSize, helpText, error} = this.props;
        let divColSize;
        if (colSize === 0) divColSize = 0;
        if (colSize === 1) divColSize = 12;
        if (colSize === 2) divColSize = 6;
        if (colSize === 3) divColSize = 4;
        if (colSize === 4) divColSize = 8;

        return (<div className={`col-12 align-bottom col-sm-${divColSize}`}>
            <div className="container p-1">
                <div className="form-group row border-bottom pb-2">
                    <label htmlFor={name} className="col-sm-12">
                        {label}
                        {field.required === true && <Fragment>{' '} <small className="text-danger">* Required field</small></Fragment>}
                    </label>
                    <Editor
                        className="col-sm-12"
                        apiKey='r7zawome3jz6v10sen489g702inbdx7hexim6spf3cy9b550'
                        value={value}
                        init={{
                            height: 450,
                            width: '100%',
                            schema: "html5",
                            plugins: 'lists advlist autolink paste fullscreen',
                            toolbar: 'undo redo | formatselect | bold italic lists | alignleft aligncenter alignright | code removeformat fullscreen'
                        }}
                        onEditorChange={(e) => onChange('editor', e, field)}
                    />
                    {helpText && <small id={name + "Help"} className="form-text text-muted">{helpText}</small>}
                    {error && <div className="col-12 alert alert-danger"><i className="fas fa-arrow-alt-circle-up" /> {error}</div>}
                    <hr />
                </div>
            </div>
        </div>);
    }
}

export default FormInputSimpleEditor;
