import React from 'react';
import classnames from "classnames";
import ShowInputError from "./ShowInputError";

const FormInputText = ({name, value, label, helpText, error, colSize}) => {
    let divColSize;
    if (colSize === 0) divColSize = 0;
    if (colSize === 1) divColSize = 12;
    if (colSize === 2) divColSize = 6;
    if (colSize === 3) divColSize = 4;
    if (colSize === 4) divColSize = 8;
    if (colSize === 5) divColSize = 3;
    return (<div className={`col-12 align-bottom col-sm-${divColSize}`}>
        <div className="container p-0">
            <div className="form-group row border-bottom pb-2">
                <label htmlFor={name} className={classnames('col-sm-12 m-0 pt-2 pb-2', { 'is-invalid': error})}>{label}</label>
                <div className="col-sm-12">
                    <input
                        disabled
                        id={name}
                        name={name}
                        type="text"
                        value={value}
                        className={classnames('form-control form-control-sm', { 'is-invalid': error})}
                        aria-describedby={name+"Help"}
                    />
                </div>
                {helpText && <><div className="col-4">&nbsp;</div><small id={name + "Help"} className="col-8 form-text text-muted">{helpText}</small></>}
                {error && <ShowInputError error={error} />}
            </div>
        </div>
    </div>);
};

export default FormInputText
