import React, { useState } from "react";
import { connect } from "react-redux";
import { File as DocumentIcon } from "react-feather";
import Modal from "../../../components/data/Modal";

const WebhookHistory = (props) => {
  const { webhooks } = props;

  const [json, setJson] = useState();
  const [showModal, setShowModal] = useState(false);

  const viewData = (viewData) => {
    setShowModal(true);
    setJson(viewData);
  };

  const closeModal = () => {
    setJson("");
    setShowModal(false);
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4>Webhook History</h4>
      </div>
      <div className="card-body overflow-auto" style={{ maxHeight: "350px" }}>
        <table className="table table-sm table-hover">
          <thead>
            <tr>
              <td>
                <b>Date</b>
              </td>
              <td>
                <b>Type</b>
              </td>
              <td>
                <b>hookType</b>
              </td>
              <td>&nbsp;</td>
            </tr>
          </thead>
          <tbody>
            {webhooks.map((webhook) => {
              let dataType = webhook.dataType;
              let hookType = webhook.hookType.replace(
                webhook.dataType + ".",
                ""
              );
              let jsonCode = JSON.parse(webhook.raw).object[dataType];
              return (
                <tr key={webhook.id}>
                  <td>{webhook.hookDate}</td>
                  <td>{dataType}</td>
                  <td>{hookType}</td>
                  <td>
                    <DocumentIcon
                      className="text-secondary"
                      onClick={() => viewData(jsonCode)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal handleClose={closeModal} show={showModal} title="Webhook">
        {json && <pre>{JSON.stringify(json, null, 2)}</pre>}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.pages.data,
});

export default connect(mapStateToProps, {})(WebhookHistory);
