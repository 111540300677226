import React from "react";
import { connect } from "react-redux";
import moment from "moment";

const UserLog = (props) => {
  const { userLog } = props;

  const timeConverter = (UNIX_timestamp) => {
    let a = new Date(UNIX_timestamp * 1000);
    return moment(a).format("l");
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4>User Log</h4>
      </div>
      <div className="card-body overflow-auto" style={{ maxHeight: "350px" }}>
        <table className="table table-sm table-hover ">
          <thead>
            <tr>
              <td>
                <b>Date</b>
              </td>
              <td>
                <b>Type</b>
              </td>
              <td>
                <b>Message</b>
              </td>
            </tr>
          </thead>
          <tbody>
            {userLog.map((row) => {
              return (
                <tr key={row.id}>
                  <td>{timeConverter(row.actionDate)}</td>
                  <td>{row.actionType}</td>
                  <td>{row.message}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.pages.data,
});

export default connect(mapStateToProps, {})(UserLog);
