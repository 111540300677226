import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getAccounts } from "../../client/actions/apiActions";

const AccountSubscription = ({ data }) => {
  const [account, setAccount] = useState({
    id: "",
    subscription: "",
    companyName: "",
    workspace: "",
    status: "",
    contactName: "",
    contactPhone: "",
  });

  useEffect(() => {
    if (data && data.account !== account) {
      //setAccount(data.account);
    }
  }, [data]);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3>Clients</h3>
            <Link to="/accounts" className="btn btn-sm btn-secondary">
              Back
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-6">Square Info for customer</div>
          <div className="col-6">
            Square Info for subscription billing history subscription
            <button className="btn btn-warning">Cancel Subscription</button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.pages.data,
});

export default connect(mapStateToProps, { getAccounts })(AccountSubscription);
