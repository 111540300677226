import React, { Component } from "react";
import "react-toggle/style.css";
import Toggle from "react-toggle";
import ShowInputError from "./ShowInputError";

class FormInputRadio extends Component {
  render() {
    const { field, name, label, helpText, colSize, value, onChange, error } =
      this.props;
    let defaultValue = this.props.defaultValue;

    if (value !== null && value !== "") defaultValue = value;

    defaultValue =
      defaultValue === 1 ||
      defaultValue === "1" ||
      defaultValue === "true" ||
      defaultValue === true;

    let divColSize;
    if (colSize === 0) divColSize = 0;
    if (colSize === 1) divColSize = 12;
    if (colSize === 2) divColSize = 6;
    if (colSize === 3) divColSize = 4;
    if (colSize === 4) divColSize = 8;
    if (colSize === 5) divColSize = 3;

    return (
      <div className={`col-${divColSize}`}>
        <div className="container p-0">
          <div className="form-group row border-bottom pb-2">
            <label htmlFor={name} className="col-sm-12">
              {label}
            </label>
            <div className="col-sm-12">
              <Toggle
                name={name}
                defaultChecked={defaultValue}
                defaultValue={defaultValue}
                icons={false}
                onChange={(e) => onChange("toggle", e, field)}
              />
            </div>
            {helpText && (
              <small id={name + "Help"} className="col-12 form-text text-muted">
                {helpText}
              </small>
            )}
            {error && <ShowInputError error={error} />}
          </div>
        </div>
      </div>
    );
  }
}

export default FormInputRadio;
