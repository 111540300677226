import React from "react";

import FormInputText from "./FormInputText";
import FormInputDisplay from "./FormInputDisplay";
import FormInputTextArea from "./FormInputTextArea";
import FormInputRadio from "./FormInputRadio";
import FormInputFile from "./FormInputFile";
import FormInputImage from "./FormInputImage";
import FormInputSelect from "./FormInputSelect";
import FormInputCheckbox from "./FormInputCheckbox";
import FormInputEditor from "./FormInputEditor";
import FormInputToggle from "./FormInputToggle";
import FormInputPassword from "./FormInputPassword";
import FormInputHidden from "./FormInputHidden";
import FormInputFileDrag from "./FormInputFileDrag";
import FormInputFancyCheckbox from "./FormInputFancyCheckbox";
import FormLinkedTable from "./FormLinkedTable";
import FormInputDate from "./FormInputDate";
import FormInputTime from "./FormInputTime";
import FormInputSimpleEditor from "./FormInputSimpleEditor";
import FormInputInlineUpload from "./FormInputInlineUpload";
import FormInputState from "./FormInputState";

const FormInput = (props) => {
  const { label, type } = props;

  switch (type) {
    case "checkbox":
      return <FormInputCheckbox {...props} />;

    case "date":
      return <FormInputDate {...props} />;

    case "display":
      return <FormInputDisplay {...props} />;

    case "editor":
    case "content":
      return <FormInputEditor {...props} />;

    case "email":
      return <FormInputText {...props} />;

    case "fancyCheckbox":
      return <FormInputFancyCheckbox {...props} />;

    case "file":
      return <FormInputFile {...props} />;
    case "drag":
      return <FormInputFileDrag {...props} />;

    case "hidden":
      return <FormInputHidden {...props} />;

    case "upload":
    case "image":
      return <FormInputImage {...props} />;

    case "inline-upload":
      return <FormInputInlineUpload {...props} />;

    case "number":
      return <FormInputText {...props} />;

    case "password":
      return <FormInputPassword {...props} />;

    case "radio":
      return <FormInputRadio {...props} />;

    case "select":
    case "dropdown":
      return <FormInputSelect {...props} />;

    case "simpleEditor":
      return <FormInputSimpleEditor {...props} />;

    case "state":
      return <FormInputState {...props} />;

    case "text":
      return <FormInputText {...props} />;

    case "textarea":
      return <FormInputTextArea {...props} />;

    case "time":
      return <FormInputTime {...props} />;

    case "toggle":
      return <FormInputToggle {...props} />;

    case "title":
      return (
        <div className={`col-12 p-2 bg-secondary text-white mb-2`}>
          <b>{label}</b>
        </div>
      );

    case "linkedTable":
      return <FormLinkedTable {...props} />;

    default:
      return <FormInputText {...props} />;
  }
};

export default FormInput;
