import React, {Component, Fragment} from 'react';
import classnames from "classnames";
import ShowInputError from "./ShowInputError";
import FormInput from "./FormInput";
import Loader from "../layouts/Loader";
import store from "../../client/store";
import {ApiURL} from "../../config";
import FormInputFileDragAndDrop from "./FormInputFileDragAndDrop";

class FormInputFileDrag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filesToUpload: [],
            fileList: [],
            uploadedFiles: [],
            pendingFiles: [],
            errorFiles: [],
            totalDocuments: 0,
            uploadWarning: '',
        };
    };

    uploadFiles = (uploadingFiles) => {

        if (typeof(uploadingFiles) !== 'undefined') {
            const uploadedFiles = Array.from(uploadingFiles);
            const formData = new FormData();
            const token = store.getState().auth.token;
            this.setState({uploadWarning: '', saveMessage: "", saveMessageType: ""});
            let uploadError = 0;
            formData.append('clientId', this.state.clientId);
            formData.append('folderId', this.state.folderId);
            formData.append('fullPath', this.state.parentFolderPath);
            if (uploadError !== 1) {
                uploadedFiles.forEach((file, i) => {
                    if (this.state.fileList.includes(file.name)) {
                        this.setState({uploadWarning: this.state.uploadWarning + " " + file.name});
                    } else {
                        this.setState({pendingFiles: [...this.state.pendingFiles, file.name]});
                        formData.append(i, file);
                    }
                });

                // SET UPLOADING TO TRUE
                this.setState({uploading: true});
                fetch(`${ApiURL}/fileUpload`, {
                    method: 'POST',
                    headers: {'Authorization': token},
                    body: formData
                })
                    .then(res => res.json())
                    .then(filesUploaded => {

                        if (typeof filesUploaded.documents !== 'undefined') {
                            filesUploaded.documents.map((file, index) => {
                                if (file.status === 1) {
                                    this.setState({
                                        uploadedFiles: [...this.state.uploadedFiles, file],
                                        ["fileName" + index + "id"]: file.id,
                                        fileList: [...this.state.fileList, file.fileName],
                                        pendingFiles: this.state.pendingFiles.filter(filename => filename !== file.fileName)
                                    });
                                } else {
                                    this.setState({
                                        errorFiles: [...this.state.errorFiles, {
                                            error: file.statusReason,
                                            name: file.fileName
                                        }],
                                        fileList: [...this.state.fileList, file.fileName],
                                        pendingFiles: this.state.pendingFiles.filter(filename => filename !== file.fileName)
                                    });
                                }
                                return (true);
                            });
                        }
                        this.setState({totalDocuments: this.state.uploadedFiles.length});
                    });
            }
        }
    };
    saveUploadFileNames = () => {
        const { uploadedFiles } = this.state;
        const token = store.getState().auth.token;
        const formData = new FormData();
        this.setState({ uploadWarning: '', saveMessage: "", saveMessageType: ""});
        formData.append('clientId', this.state.clientId);
        uploadedFiles.forEach((file, index) => {
            formData.append("file" + index + "Name", this.state["fileName" + index]);
            formData.append("file" + index + "ID", this.state["fileName" + index + "id"]);
        });
        formData.append('filesLength', uploadedFiles.length);

        fetch(`${ApiURL}/fileUpdate`, {
            method: 'POST',
            headers: {'Authorization': token},
            body: formData
        })
            .then(res => res.json())
            .then(output => {
                if (output.status === 1)
                {
                    this.setState({uploadFiles: false, uploadedFiles: [], totalDocuments: 0});
                    if (this.state.parentFolderPath !== "/" && this.state.parentFolderPath !== "") {
                        this.selectFolder(this.state.parentFolderPath, this.state.sortDir, this.state.sortField, this.state.folderId);
                    } else {
                        this.selectClient(this.state.clientId)
                    }
                }
                else
                {
                    this.setState({saveMessage: "Update Failed!", saveMessageType: 0});
                }
            })

    };
    cancelUpload = () => {
        this.setState({
            uploadedFiles: [],
            uploadWarning: '',
            pendingFiles: [],
            fileList: [],
            errorFiles: [],
            editFile: false,
            addFolder: false,
            editFolder: false,
            uploadFiles: false,
            totalDocuments: 0
        })
    };

    render() {

        const { colSize, name, label, error, helpText } = this.props;
        const { totalDocuments, uploadWarning, uploadedFiles, pendingFiles, saveMessageType, saveMessage, errorFiles } = this.state;

        let divColSize;
        if (colSize === 0) divColSize = 0;
        if (colSize === 1) divColSize = 12;
        if (colSize === 2) divColSize = 6;
        if (colSize === 3) divColSize = 4;
        if (colSize === 4) divColSize = 8;

        return (<div className={`col-12 align-bottom col-sm-${divColSize}`}>
            <div className="container p-0">
                <div className="form-group row border-bottom pb-2">
                    <label htmlFor={name} className={classnames('col-sm-12 m-0 pt-2 pb-2', { 'is-invalid': error})}>{label}</label>
                    <div className="col-12">
                        <h3>Upload a Document</h3>
                        <FormInputFileDragAndDrop
                            handleDrop={this.uploadFiles}
                            totalDocuments={totalDocuments}
                        >
                            {totalDocuments !== 0 && <div className="col-12 text-end">Total Files: {totalDocuments}</div>}
                            {saveMessageType === 0 && <div className="text-center alert alert-danger py-2 mb-2 rounded">{saveMessage}</div>}
                            {saveMessageType === 1 && <div className="text-center alert alert-success py-2 mb-2 rounded">{saveMessage}</div>}
                            <div className="DragDropFiles">
                                {uploadWarning && <div className="alert alert-danger py-2 mb-2 rounded">A file with the same name has already been uploaded, if the file you are uploading is a different file then rename it and try again. : {uploadWarning}</div>}
                                {uploadedFiles && <Fragment>
                                    {uploadedFiles.map((file, index) => {
                                        return (<div key={index} className="container">
                                            <div className="form-group row border-bottom pb-2 bg-dark text-light"><label
                                                htmlFor="fileName0" className="col-sm-12 m-0 pt-2 pb-2">File Name: {file.fileName}</label>
                                            </div>
                                            <FormInput
                                                type="hidden"
                                                name={`fileName${index}id`}
                                                value={this.state["fileName" + index + "id"]}
                                            />
                                            <FormInput
                                                type="text"
                                                name={`fileName${index}`}
                                                value={this.state["fileName" + {index}]}
                                                label="File Name"
                                                placeholder="name ..."
                                                helpText="Write short title of what the file is, example: '2018 personal tax return'"
                                                onChange={this.onChange}
                                            />
                                        </div>);
                                    })}
                                </Fragment>}
                                {pendingFiles && <Fragment>
                                    {pendingFiles.map((file, index) =>
                                        <div key={index} className="container my-1">
                                            <div className="form-group row bg-dark text-light">
                                                <label htmlFor="fileName0" className="col-sm-12 m-0 pt-2 pb-2 small">File Name: {file} </label>
                                                <Loader />
                                            </div>
                                        </div>
                                    )}
                                </Fragment>}
                                {errorFiles && <Fragment>
                                    {errorFiles.map((file, index) =>
                                        <div key={index} className="container">
                                            <div className="form-group row border-bottom pb-2 bg-danger text-light"><label
                                                htmlFor="fileName0" className="col-sm-12 m-0 pt-2 pb-2">File Name: {file.name} - Status: {file.error}</label>
                                            </div>
                                        </div>
                                    )}
                                </Fragment>}
                            </div>
                        </FormInputFileDragAndDrop>
                        {totalDocuments > 0 && <button className="btn btn-success" onClick={()=>this.saveUploadFileNames()}>Save & Close</button>}
                        {totalDocuments === 0 && <button className="btn btn-secondary" onClick={()=>this.cancelUpload()}>Back</button>}
                    </div>
                    {helpText && <Fragment><div className="col-4">&nbsp;</div><small id={name + "Help"} className="col-8 form-text text-muted">{helpText}</small></Fragment>}
                    {error && <ShowInputError error={error} />}
                </div>
            </div>
        </div>)
    }
}
export default FormInputFileDrag
