import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadDashboard } from "../client/actions/apiActions";
import DashboardNotifications from "../components/dashboard/DashboardNotifications";
import DashboardNewUsers from "../components/dashboard/DashboardNewUsers";

const Dashboard = (props) => {
  const [newUsers, setNewUsers] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    props.loadDashboard();
  }, []);

  useEffect(() => {
    if (props.data?.displayType === "dashboard") {
      setNewUsers(props.data.newUsers);
      setNotifications(props.data.dashboardNotifications);
      setReload(!reload);
    }
  }, [props]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-sm-6">
          <DashboardNotifications notifications={notifications} />
        </div>
        <div className="col-12 col-sm-6">
          <DashboardNewUsers newUsers={newUsers} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth.user,
  data: state.pages.data,
});

export default connect(mapStateToProps, { loadDashboard })(Dashboard);
