import React from "react";
import { Link } from "react-router-dom";

const Account = ({ account, subscriptionTypes }) => {
  return (
    <tr>
      <td>{account.workspace}</td>
      <td>{account.companyName}</td>
      <td>
        {account.contactName} - {account.contactPhone}
      </td>
      <td>
        {
          subscriptionTypes.filter(
            (sub) => sub.id === account.subscription.subscriptionTypeId
          )[0].name
        }
      </td>
      <td>{account.status === "1" ? "Pending" : "Approved"}</td>
      <td>
        <Link to={`/accounts/${account.id}`} className="btn btn-warning btn-sm">
          Manage
        </Link>
      </td>
    </tr>
  );
};
export default Account;
