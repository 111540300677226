const CreditCardsContainer = (props) => {
  let { name, expiration, type } = props;
  let number = props.number.replace(/\s/g, "");
  if (type === "visa") {
    number = number.replace(/(\D{4})/g, "$1 ").trim();
  }

  return (
    <div className="rccs">
      <div className={`rccs__card rccs__card--${type}`}>
        <div className="rccs__card--front">
          <div className="rccs__card__background"></div>
          <div className="rccs__issuer"></div>
          <div className="rccs__cvc__front"></div>
          <div className="rccs__number rccs--focused rccs--filled">
            {number}
          </div>
          <div className="rccs__name">{name}</div>
          <div className="rccs__expiry">
            <div className="rccs__expiry__valid">valid thru</div>
            <div className="rccs__expiry__value">{expiration}</div>
          </div>
          <div className="rccs__chip"></div>
        </div>
      </div>
    </div>
  );
};
export default CreditCardsContainer;
