import {
  File as DocumentIcon,
  Settings as SettingsIcon,
  Sliders as SlidersIcon,
  Users as UsersIcon,
} from "react-feather";

// Auth
import SignIn from "../../pages/auth/SignIn";
import SignOut from "../../pages/auth/SignOut";
import ResetPassword from "../../pages/auth/ResetPassword";
import Page404 from "../../pages/errors/Page404";
import Page500 from "../../pages/errors/Page500";

// Notifications
import Notifications from "../../components/ProfileNotifications";

//
import TeamMembers from "../../pages/Users";
import Users from "../../pages/Users";
import EmailTemplates from "../../pages/EmailTemplates";

// default pages
import Profile from "../../components/Profile";

// Documentation
import ProfilePassword from "../../components/ProfilePassword";
import Content from "../../pages/Content";
import ContentPage from "../../components/ContentPage";

import Dashboard from "../../pages/Dashboard";

import Config from "../../pages/Config";
import SubscriptionTypes from "../../pages/SubscriptionTypes";

import Accounts from "../../pages/Accounts";
import AccountManage from "../../pages/Account/AccountManage";
import AccountSubscription from "../../pages/Account/AccountSubscription";
import Tags from "../../pages/Tags";
import Locations from "../../pages/Locations";
import DiscountCodes from "../../pages/DiscountCodes";
import FAQs from "../../pages/FAQs";
import Invite from "../../pages/auth/Invite";

const dashboardRoutes = {
  path: "/",
  name: "Dashboard",
  component: Dashboard,
  icon: SlidersIcon,
  containsHome: true,
  isPrivate: true,
  children: null,
};

const documentsRoutes = {
  path: "/",
  name: "",
  icon: DocumentIcon,
  children: [
    {
      path: "/my-team",
      name: "My Team",
      component: Users,
      isPrivate: true,
      icon: DocumentIcon,
      containsHome: true,
      children: null,
    },
    {
      path: "/accounts",
      name: "Accounts",
      component: Accounts,
      isPrivate: true,
      icon: DocumentIcon,
      containsHome: true,
      children: null,
    },
    {
      path: "/accounts/:id",
      name: "AccountManage",
      component: AccountManage,
      isPrivate: true,
      icon: DocumentIcon,
      containsHome: true,
      children: null,
    },
    {
      path: "/tags",
      name: "Tags",
      component: Tags,
      isPrivate: true,
      icon: DocumentIcon,
      containsHome: true,
      children: null,
    },
    {
      path: "/tags/:id",
      name: "Tags",
      component: Tags,
      isPrivate: true,
      icon: DocumentIcon,
      containsHome: true,
      children: null,
    },
    {
      path: "/locations",
      name: "Locations",
      component: Locations,
      isPrivate: true,
      icon: DocumentIcon,
      containsHome: true,
      children: null,
    },
    {
      path: "/locations/:id",
      name: "Locations",
      component: Locations,
      isPrivate: true,
      icon: DocumentIcon,
      containsHome: true,
      children: null,
    },
    {
      path: "/content",
      name: "Content",
      component: Content,
      isPrivate: true,
      icon: DocumentIcon,
      containsHome: true,
      children: null,
    },
    {
      path: "/content/:id",
      name: "Content",
      component: Content,
      isPrivate: true,
      icon: DocumentIcon,
      containsHome: true,
      children: null,
    },
    {
      path: "/accounts/:id/subscription",
      name: "AccountSubscription",
      component: AccountSubscription,
      isPrivate: true,
      icon: DocumentIcon,
      containsHome: true,
      children: null,
    },
    {
      path: "/subscription-types",
      name: "Subscription Types",
      component: SubscriptionTypes,
      isPrivate: true,
      icon: DocumentIcon,
      containsHome: true,
      children: null,
    },
    {
      path: "/subscription-types/:id",
      name: "Subscription Types",
      component: SubscriptionTypes,
      isPrivate: true,
      icon: DocumentIcon,
      containsHome: true,
      children: null,
    },
    {
      path: "/discount-codes",
      name: "Discount Codes",
      component: DiscountCodes,
      isPrivate: true,
      icon: DocumentIcon,
      containsHome: false,
      children: null,
    },
    {
      path: "/discount-codes/:id",
      name: "Discount Codes",
      component: DiscountCodes,
      isPrivate: true,
      icon: DocumentIcon,
      containsHome: false,
      children: null,
    },
    {
      path: "/faqs",
      name: "FAQs",
      component: FAQs,
      isPrivate: true,
      icon: DocumentIcon,
      containsHome: false,
      children: null,
    },
    {
      path: "/faqs/:id",
      name: "FAQs",
      component: FAQs,
      isPrivate: true,
      icon: DocumentIcon,
      containsHome: false,
      children: null,
    },
  ],
};

// SETTINGS LINK
const settingsRoutes = {
  path: "/settings",
  name: "Settings",
  icon: SettingsIcon,
  children: [
    {
      isPrivate: true,
      path: "/settings/email-templates",
      name: "Email Templates",
      component: EmailTemplates,
    },
    {
      isPrivate: true,
      path: "/settings/email-templates/:id",
      name: "Email Templates",
      component: EmailTemplates,
    },
    {
      isPrivate: true,
      path: "/settings/users",
      name: "Users",
      component: Users,
    },
    {
      isPrivate: true,
      path: "/settings/users/:id",
      name: "Users",
      component: Users,
    },
    {
      isPrivate: true,
      path: "/settings/config",
      name: "Config",
      component: Config,
    },
    {
      isPrivate: true,
      path: "/settings/config/:id",
      name: "Config",
      component: Config,
    },
  ],
};

const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: UsersIcon,
  isPrivate: false,
  badgeColor: "secondary",
  badgeText: "Special",
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      isPrivate: false,
      component: SignIn,
    },
    {
      path: "/auth/sign-out",
      name: "Sign Out",
      isPrivate: false,
      component: SignOut,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      isPrivate: false,
      component: ResetPassword,
    },
    {
      path: "/invite/:inviteCode",
      name: "Invite",
      isPrivate: false,
      component: Invite,
    },
  ],
};

// ROUTES NOT VISIBLE IN THE SIDEBAR
const privateRoutes = {
  path: "/privacy",
  name: "Privacy",
  children: [
    {
      path: "/accounts/:accountId",
      name: "Accounts",
      component: AccountManage,
      isPrivate: true,
      icon: DocumentIcon,
      containsHome: true,
      children: null,
    },
    {
      isPrivate: true,
      path: "/organizations/users/:organizationId",
      name: "Organization Users",
      component: TeamMembers,
    },
    {
      isPrivate: true,
      path: "/organizations/users/:organizationId/:id",
      name: "Organization Users",
      component: TeamMembers,
    },
    {
      isPrivate: true,
      path: "/privacy",
      name: "Privacy",
      component: ContentPage,
    },
    {
      isPrivate: true,
      path: "/support-center",
      name: "Support",
      component: ContentPage,
    },
    {
      isPrivate: true,
      path: "/help-center",
      name: "Help Center",
      component: ContentPage,
    },
    {
      isPrivate: true,
      path: "/terms-of-service",
      name: "Terms of Service",
      component: ContentPage,
    },
    {
      isPrivate: true,
      path: "/profile",
      name: "Profile",
      component: Profile,
    },
    {
      isPrivate: true,
      path: "/profile-password",
      name: "Password",
      component: ProfilePassword,
    },
    {
      isPrivate: true,
      path: "/profile-notifications",
      name: "Notifications",
      component: Notifications,
    },
    {
      isPrivate: true,
      path: "/notifications",
      name: "Notifications",
      component: Notifications,
    },
    {
      isPrivate: false,
      path: "/404",
      name: "404 Page",
      component: Page404,
    },
    {
      isPrivate: false,
      path: "/500",
      name: "500 Page",
      component: Page500,
    },
    {
      isPrivate: true,
      path: "/auth/sign-out",
      name: "sign out",
      component: SignOut,
    },
  ],
};

export const dashboard = [
  dashboardRoutes,
  documentsRoutes,
  settingsRoutes,
  privateRoutes,
];

// Auth specific routes
export const page = [authRoutes];

// ROUTES TO SHOW IN NAVBAR
export const adminLinks = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    icon: SlidersIcon,
    containsHome: false,
    isPrivate: true,
    children: null,
  },
  {
    path: "/accounts",
    name: "Accounts",
    component: Accounts,
    isPrivate: true,
    icon: DocumentIcon,
    containsHome: false,
    children: null,
  },
  {
    path: "/locations",
    name: "Locations",
    component: Accounts,
    isPrivate: true,
    icon: DocumentIcon,
    containsHome: false,
    children: null,
  },
  {
    path: "/tags",
    name: "Tags",
    component: Accounts,
    isPrivate: true,
    icon: DocumentIcon,
    containsHome: false,
    children: null,
  },
  {
    path: "/content",
    name: "Content",
    component: Content,
    isPrivate: true,
    icon: DocumentIcon,
    containsHome: false,
    children: null,
  },
  {
    path: "/discount-codes",
    name: "Discount Codes",
    component: DiscountCodes,
    isPrivate: true,
    icon: DocumentIcon,
    containsHome: false,
    children: null,
  },
  {
    path: "/faqs",
    name: "FAQs",
    component: FAQs,
    isPrivate: true,
    icon: DocumentIcon,
    containsHome: false,
    children: null,
  },
  {
    path: "/subscription-types",
    name: "Subscription Types",
    component: SubscriptionTypes,
    isPrivate: true,
    icon: DocumentIcon,
    containsHome: true,
    children: null,
  },
  {
    path: "/settings",
    name: "Settings",
    langName: "settings",
    //header: "Settings",
    icon: SettingsIcon,
    children: [
      {
        isPrivate: true,
        path: "/settings/email-templates",
        name: "Email Templates",
        component: EmailTemplates,
      },
      {
        isPrivate: true,
        path: "/settings/users",
        name: "Users",
        component: Users,
      },
      {
        isPrivate: true,
        path: "/settings/config",
        name: "Config",
        component: Config,
      },
    ],
  },
];
