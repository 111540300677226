import { ChevronDown as SortDown, ChevronUp as SortUp } from "react-feather";
import React from "react";

export const DataListColumns = ({
  columns,
  sortDirection,
  sortFieldName,
  changeSort,
  hasStatus,
}) => {
  return (
    <tr className="table-primary">
      {columns.map((column, index) => {
        if (column.dataField === "active" || column.dataField === "status")
          hasStatus = true;
        if (column.type === "bool") {
          if (column.sort === true) {
            return (
              <th key={index} className="text-center cursor-pointer">
                {column.displayName}
              </th>
            );
          } else {
            return (
              <th key={index} className="text-center">
                {column.displayName}
              </th>
            );
          }
        } else {
          if (column.sort === true) {
            return (
              <th
                key={index}
                className="cursor-pointer"
                onClick={() => changeSort(column.dataField, sortDirection)}
              >
                {sortFieldName === column.dataField &&
                  typeof sortDirection !== "undefined" &&
                  sortDirection === "ASC" && <SortDown size={12} />}
                {sortFieldName === column.dataField &&
                  typeof sortDirection !== "undefined" &&
                  sortDirection === "DESC" && <SortUp size={12} />}
                {column.displayName}
              </th>
            );
          } else {
            if (column.dataField === "tools") {
              return (
                <th key={index} className="text-end">
                  {column.displayName}
                </th>
              );
            } else {
              return <th key={index}>{column.displayName}</th>;
            }
          }
        }
      })}
    </tr>
  );
};
