import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { dashboard as dashboardRoutes, page as pageRoutes } from "./index";

import DashboardLayout from "../../components/layouts/Dashboard";
import AuthLayout from "../../components/layouts/Auth";
import Page404 from "../../pages/errors/Page404";
import PropTypes from "prop-types";
import ScrollToTop from "../../components/ScrollToTop";
import { connect } from "react-redux";
import PrivateRoute from "./PrivateRoute";

const childRoutes = (Layout, routes, auth) =>
  routes.map(
    (
      { children, isPrivate, hideRoute, path, component: Component, ...rest },
      index
    ) =>
      children ? (
        children.map(
          ({ isPrivate, hideRoute, path, component: Component }, index2) =>
            isPrivate === true ? (
              <Route
                key={index2}
                auth={auth}
                exact
                path={path}
                element={<PrivateRoute auth={auth} />}
              >
                <Route
                  exact
                  auth={auth}
                  path={path}
                  element={
                    <Layout>
                      <Component />
                    </Layout>
                  }
                />
              </Route>
            ) : (
              <Route
                key={index2}
                path={path}
                auth={auth}
                exact
                element={
                  <Layout>
                    <Component />
                  </Layout>
                }
              />
            )
        )
      ) : isPrivate === true ? (
        <Route
          key={index}
          auth={auth}
          exact
          path={path}
          element={<PrivateRoute auth={auth} />}
        >
          <Route
            exact
            auth={auth}
            path={path}
            element={
              <Layout>
                <Component />
              </Layout>
            }
          />
        </Route>
      ) : (
        <Route
          key={index}
          auth={auth}
          path={path}
          exact
          element={
            <Layout>
              <Component />
            </Layout>
          }
        />
      )
  );

const AppRoutes = ({ auth }) => {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          {childRoutes(DashboardLayout, dashboardRoutes, auth)}
          {childRoutes(AuthLayout, pageRoutes, auth)}
          <Route
            path="*"
            element={
              <AuthLayout>
                <Page404 />
              </AuthLayout>
            }
          />
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

AppRoutes.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AppRoutes);
