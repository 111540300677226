import React, { useEffect, useState } from "react";
import DataOverview from "../components/data/DataOverview";
import { useLocation, useMatch, useParams } from "react-router-dom";
import Loader from "../components/layouts/Loader";

const tableName = "subscriptionTypes";
const editPathName = "/subscription-types/:id";
const tableColumns = [
  {
    dataField: "name",
    text: "Name",
    displayName: "Name",
    sort: true,
  },
  {
    dataField: "price",
    displayName: "Price",
    sort: true,
  },
  {
    dataField: "sortOrder",
    displayName: "Sort",
    sort: true,
  },
  {
    dataField: "status",
    displayName: "Status",
    type: "bool",
    sort: true,
  },
  {
    dataField: "tools",
    displayName: "Tools",
    text: "Tools",
    sort: false,
  },
];
const fieldData = [
  {
    fieldName: "name",
    displayName: "Name",
    placeholder: "Name",
    fieldType: "text",
    colSize: 2,
    required: true,
    requiredMessage: "Field is required!",
  },
  {
    fieldName: "price",
    displayName: "Price",
    placeholder: "",
    fieldType: "text",
    colSize: 2,
    required: false,
  },
  {
    fieldName: "sortOrder",
    displayName: "Sort",
    placeholder: "",
    fieldType: "number",
    colSize: 2,
    required: false,
  },
  {
    fieldName: "status",
    displayName: "Status",
    placeholder: "Status",
    fieldType: "toggle",
    colSize: 2,
    required: false,
    requiredMessage: "Field is required!",
  },
  {
    fieldName: "devPaymentButtonKey",
    displayName: "Dev Payment Button Key",
    placeholder: "Payment Button Key",
    fieldType: "text",
    colSize: 2,
    required: false,
    requiredMessage: "Field is required!",
  },
  {
    fieldName: "paymentButtonKey",
    displayName: "Prod Payment Button Key",
    placeholder: "Payment Button Key",
    fieldType: "text",
    colSize: 2,
    required: false,
    requiredMessage: "Field is required!",
  },
  {
    fieldName: "features",
    displayName: "Features",
    placeholder: "Features",
    fieldType: "textarea",
    colSize: 1,
    required: false,
  },
];
const fieldDataDefault = {
  name: "",
  sort: "",
  price: "",
  status: true,
};

const SubscriptionTypes = (props) => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const match = useMatch(editPathName);
  let parentRoute = pathname;

  if (match) {
    parentRoute = match.pattern.path.replace("/:id", "");
  }

  let title = "Subscription Types";
  return (
    <DataOverview
      id={id ?? ""}
      tableName={tableName}
      pageMode={id ? "edit" : "list"}
      route={pathname}
      parentRoute={parentRoute}
      sortFieldName={"sortOrder"}
      sortDirection={"ASC"}
      pageTitle={title}
      listTitle={title}
      listSubTitle={""}
      addTitle={`Add ${title}`}
      addSubTitle={""}
      editTitle={`Edit ${title}`}
      editSubTitle={""}
      tableColumns={tableColumns}
      fieldData={fieldData}
      fieldDataDefault={fieldDataDefault}
    />
  );
};

export default SubscriptionTypes;
