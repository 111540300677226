import React, { Component } from "react";

class FormInputFileDragAndDrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drag: false,
      dragging: false,
      files: [],
    };
  }
  dropRef = React.createRef();
  hiddenFileInput = React.createRef();
  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: true });
  };
  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });
  };
  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleDrop(e.dataTransfer.files);
      //e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };

  componentDidMount() {
    let div = this.dropRef.current;
    div.addEventListener("dragenter", this.handleDragIn);
    div.addEventListener("dragleave", this.handleDragOut);
    div.addEventListener("dragover", this.handleDrag);
    div.addEventListener("drop", this.handleDrop);
  }
  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener("dragenter", this.handleDragIn);
    div.removeEventListener("dragleave", this.handleDragOut);
    div.removeEventListener("dragover", this.handleDrag);
    div.removeEventListener("drop", this.handleDrop);
  }
  render() {
    const { totalDocuments } = this.props;
    const handleClick = (event) => {
      this.hiddenFileInput.current.click();
    };
    const handleChange = (e) => {
      const fileUploaded = e.target.files;
      this.setState({ drag: false });
      if (fileUploaded) this.props.handleDrop(fileUploaded);
    };

    return (
      <div className="DragDropArea" ref={this.dropRef}>
        <input
          type="file"
          multiple
          id="uploadButton"
          name="uploadButton"
          ref={this.hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
        />
        {totalDocuments === 0 && (
          <>
            {this.state.drag === false && (
              <div className="text-center text-dark py-1 mb-0 rounded border border-warning border-dashed border-4">
                Drag one or more files here to upload ...
                <br />
                - OR -<br />
                <button className="btn btn-warning" onClick={handleClick}>
                  Click Here to Upload a File
                </button>
              </div>
            )}
            {this.state.drag === true && (
              <div className="text-center bg-success text-dark py-5 mb-4 rounded border">
                Drop files to upload them ...
              </div>
            )}
          </>
        )}
        {this.props.children}
      </div>
    );
  }
}
export default FormInputFileDragAndDrop;
