import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { updateCreditCard } from "../../../client/actions/apiActions";
import Loader from "../../../components/layouts/Loader";

import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import CreditCards from "../../../components/CreditCards";
import { appId, locationId } from "../../../config";

const AccountManageCreditCard = (props) => {
  const { data, id, getAccount, card, squareCustomer } = props;
  const [loading, setLoading] = useState(false);
  const [updateCard, setUpdateCard] = useState(false);

  useEffect(() => {
    if (data.dataType === "updateCreditCard") {
      setUpdateCard(false);
      props.getAccount(id);
      setLoading(true);
    }
  }, [data]);

  useEffect(() => {
    getAccount(id);
    setLoading(false);
  }, [id]);

  const updateCreditCard = (response) => {
    if (response.status === "OK") {
      let nonce = response.token;
      props.updateCreditCard(
        nonce,
        squareCustomer.id,
        squareCustomer.cards[0].id
      );
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="card">
      <div className="card-header">
        <h4>Payment Info</h4>
      </div>
      <div className="card-body">
        {!updateCard && (
          <>
            <CreditCards
              name={card.name}
              number={card.number}
              cvc={card.cvc}
              type={card.type}
              expiration={card.expiration}
            />
            <br />
            <button
              className="btn btn-sm btn-secondary"
              onClick={() => setUpdateCard(true)}
            >
              Update Card
            </button>
          </>
        )}
        {updateCard && (
          <>
            <PaymentForm
              applicationId={appId}
              cardTokenizeResponseReceived={(response) => {
                updateCreditCard(response);
              }}
              createPaymentRequest={() => ({
                countryCode: "US",
                currencyCode: "USD",
                total: {
                  amount: 100,
                  label: "Total",
                },
              })}
              locationId={locationId}
            >
              <CreditCard>Update Card</CreditCard>
            </PaymentForm>
            <br />
            <button
              className="btn btn-sm btn-secondary"
              onClick={() => setUpdateCard(false)}
            >
              Cancel
            </button>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.pages.data,
});

export default connect(mapStateToProps, {
  updateCreditCard,
})(AccountManageCreditCard);
