import React, { useEffect, useState } from "react";
import FormInput from "../../components/form/FormInput";
import { loadInvite, saveAccount } from "../../client/actions/apiActions";

import logo from "../../assets/img/logo/logo-lng.png";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
const Invite = (props) => {
  const navigate = useNavigate();
  const { inviteCode } = useParams();
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [workPhone, setWorkPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [processing, setProcessing] = useState(false);

  const onCreateAccount = () => {
    props.saveAccount(
      inviteCode,
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      cellPhone,
      workPhone
    );
  };
  // initial update only
  useEffect(() => {
    props.loadInvite(inviteCode);
  }, []);

  // process props update
  useEffect(() => {
    const { pageType, status } = props.data;
    if (pageType === "loadInvite") {
      if (props.data.person === true) {
        setLoading(false);
        setFirstName(props.data.firstName);
        setLastName(props.data.lastName);
        setEmail(props.data.email);
        setWorkPhone(props.data.workPhone);
      } else {
        setMessage("Error loading invite");
      }
    }
    if (pageType === "saveAccount") {
      if (status === 1) {
        navigate("/auth/sign-in");
      } else {
        setMessage(props.data.message);
      }
    }
  }, [props]);

  if (loading) {
    return (
      <>
        <div className="conatainer">
          <div className="row">
            <div className="col-12 text-center">
              <img src={logo} className="img-fluid" alt="logo" />
              <h1>Loading Invite</h1>
            </div>
          </div>
          {message && (
            <div className="row">
              <div className="col-12 alert alert-danger p-2 text-dark">
                {message}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="my-5">Pinellas Christian Businesses</h1>
              </div>
              <div className="col-12 mb-4 text-center">
                <h5>
                  You have been invited to join Pinellas Christian Businesses
                </h5>
              </div>
            </div>
            {message && (
              <div className="row">
                <div className="col-12 alert alert-danger p-2 text-dark">
                  {message}
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <h2>Personal Information</h2>
              </div>
            </div>
            <div className="row">
              <FormInput
                id="firstName"
                name="firstName"
                label="First Name"
                value={firstName}
                placeholder={"first name"}
                colSize={2}
                onChange={(type, e, field) => setFirstName(e.target.value)}
              />
              <FormInput
                id="lastName"
                name="lastName"
                label="Last Name"
                placeholder={"last name"}
                value={lastName}
                colSize={2}
                onChange={(type, e, field) => setLastName(e.target.value)}
              />
            </div>
            <div className="row">
              <div className="col-12 mt-4">
                <h2>Log In Information</h2>
              </div>
              <div className="col-12">
                <small>
                  Make sure to enter in a strong password to keep your account
                  secure.
                </small>
              </div>
            </div>
            <div className="row">
              <FormInput
                type="display"
                id="email"
                name="email"
                label="Email"
                placeholder={"email address"}
                value={email}
                colSize={1}
                onChange={(type, e, field) => setEmail(e.target.value)}
              />
              <FormInput
                id="password"
                type="password"
                name="password"
                label="Password"
                placeholder={"password"}
                value={password}
                colSize={1}
                onChange={(type, e, field) => setPassword(e.target.value)}
              />
              <FormInput
                id="confirmPassword"
                type="password"
                name="confirmPassword"
                label="Confirm Password"
                placeholder={"confirm password"}
                value={confirmPassword}
                colSize={1}
                onChange={(type, e, field) =>
                  setConfirmPassword(e.target.value)
                }
              />
            </div>
            <div className="row mt-2">
              <div className="col-6">
                <a
                  href="https://www.simplycollaborate.com"
                  className="btn btn-warning btn-sm"
                >
                  Cancel
                </a>
              </div>
              <div className="col-6 text-end">
                <button
                  className="btn btn-sm btn-success"
                  onClick={() => onCreateAccount()}
                >
                  Create Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth.user,
  data: state.pages.data,
});
export default connect(mapStateToProps, { loadInvite, saveAccount })(Invite);
