import React from "react";
import classnames from "classnames";
import PasswordStrengthMeter from "./PasswordStrengthMeter";
import ShowInputError from "./ShowInputError";

const FormInputText = ({
  field,
  type,
  name,
  value,
  label,
  colSize,
  placeholder,
  helpText,
  onChange,
  error,
  hideMeter,
  hideBorder,
  onBlur,
  children,
}) => {
  let divColSize;
  if (colSize === 0) divColSize = 0;
  if (colSize === 1) divColSize = 12;
  if (colSize === 2) divColSize = 6;
  if (colSize === 3) divColSize = 4;
  if (colSize === 4) divColSize = 8;
  let password = value;
  if (typeof value === "undefined") password = "";
  return (
    <div className={`col-12 align-bottom col-sm-${divColSize}`}>
      <div
        className={`form-group row ${!hideBorder ? "border-bottom" : ""} pb-2`}
      >
        <label
          htmlFor={name}
          className={classnames("col-sm-12 m-0 pt-2 pb-2", {
            "is-invalid": error,
          })}
        >
          {label}
        </label>
        <div className="col-sm-12">
          <input
            id={name}
            name={name}
            type="password"
            value={password}
            className={classnames("form-control form-control-sm", {
              "is-invalid": error,
            })}
            onChange={(e) => onChange(type, e, field)}
            onBlur={onBlur}
            aria-describedby={name + "Help"}
            placeholder={placeholder}
          />
          {children && (
            <>
              <br />
              {children}
              <br />
            </>
          )}
          {error && <ShowInputError error={error} />}
          {!hideMeter && <PasswordStrengthMeter password={password} />}
        </div>
      </div>
      {helpText && (
        <small id={name + "Help"} className="col-12 form-text text-muted">
          {helpText}
        </small>
      )}
    </div>
  );
};

export default FormInputText;
