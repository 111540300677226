import { Button } from "reactstrap";
import {
  Download as DownloadIcon,
  Filter as FilterIcon,
  PlusSquare as AddIcon,
  UserPlus as UserIcon,
} from "react-feather";
import React from "react";
import { ApiURL } from "../../config";
import { useNavigate } from "react-router-dom";

export const DataListAddButtons = (props) => {
  const navigate = useNavigate();
  let addPermissions;
  let exportPermissions;

  if (typeof props.userPermissions != "undefined") {
    if (props.tableName === "users") {
      addPermissions = props.userPermissions.usersCanAdd === "1";
      exportPermissions = props.userPermissions.usersCanExport === "1";
    } else if (props.tableName === "organizations") {
      addPermissions = props.userPermissions.organizationsCanAdd === "1";
      exportPermissions = props.userPermissions.organizationsCanExport === "1";
    } else {
      addPermissions = props.userPermissions.tablesCanAdd === "1";
      exportPermissions = props.userPermissions.tablesCanExport === "1";
    }
  }

  const exportData = () => {
    window.open(ApiURL + "/export/" + props.data.code);
  };

  const addRecord = () => {
    navigate(props.parentRoute + "/0");
  };

  return (
    <div className="col-12 col-sm text-start mb-2">
      {props.tableName === "users" && (
        <Button
          color="primary"
          onClick={() => props.onInvite()}
          className="ms-1"
        >
          <UserIcon size={18} /> Invite User
        </Button>
      )}

      {props.tableName !== "users" && addPermissions === true && (
        <Button color="primary" onClick={() => addRecord()} className="ms-1">
          <AddIcon size={18} /> {props.addTitle}
        </Button>
      )}

      {exportPermissions === true && (
        <>
          {typeof props.settings !== "undefined" && (
            <>
              {props.settings.showExport !== false && (
                <Button
                  color="secondary"
                  onClick={() => exportData()}
                  className="ms-1"
                >
                  <DownloadIcon size={18} /> Export Data
                </Button>
              )}
            </>
          )}
        </>
      )}

      {typeof props.settings !== "undefined" && (
        <>
          {props.settings.showFilters !== false && (
            <Button
              color="secondary"
              onClick={() => props.toggleFilters()}
              className="ms-1"
            >
              <FilterIcon size={18} /> {props.showFilters ? "Hide" : "Show"}{" "}
              Filters
            </Button>
          )}
        </>
      )}
    </div>
  );
};
