import React from "react";
import { connect } from "react-redux";

const PaymentHistory = (props) => {
  const { squareInvoices } = props;

  return (
    <div className="card">
      <div className="card-header">
        <h4>Payment History</h4>
      </div>
      <div className="card-body">
        <table className="table table-sm table-hover">
          <thead>
            <tr>
              <td>
                <b>Invoice #</b>
              </td>
              <td>
                <b>Status</b>
              </td>
              <td>
                <b>Date</b>
              </td>
              <td>
                <b>Subscription</b>
              </td>
              <td>
                <b>Cost</b>
              </td>
            </tr>
          </thead>
          <tbody>
            {squareInvoices?.map((invoice) => {
              return (
                <tr key={invoice.invoice_number}>
                  <td>{invoice.invoice_number}</td>
                  <td>{invoice.status}</td>
                  <td>{invoice.title}</td>
                  <td>{invoice.updated_at}</td>
                  <td>
                    $
                    {invoice.payment_requests[0].computed_amount_money.amount /
                      100}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.pages.data,
});

export default connect(mapStateToProps, {})(PaymentHistory);
