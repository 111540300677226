import React, {Component} from "react";
import DataOverview from "./data/DataOverview";
import {connect} from "react-redux";
import { getProfileInfo } from "../client/actions/apiActions";
import Loader from "./layouts/Loader";
import ProfileLinks from "./ProfileLinks";

const tableName = "users";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewMode: 'loading',
      firstName: '',
      lastName: '',
      email: '',

      fieldData: [
        {
          displayName: "First Name",
          fieldName: "firstName",
          fieldType: "text",
          colSize: 3,
          required: true
        },
        {
          displayName: "Last Name",
          fieldName: "lastName",
          fieldType: "text",
          colSize: 3,
          required: true
        },
        {
          displayName: "Email",
          fieldName: "email",
          fieldType: "display",
          colSize: 3,
          required: true
        },
        {
          displayName: "Phone",
          fieldName: "phone",
          fieldType: "text",
          colSize: 3
        },
      ],
      fieldDataResults: []
    };
  }

  componentDidMount() {
    this.props.getProfileInfo();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props)
    {
      let fieldDataResults = this.props.data.userProfile;

      this.setState({
        viewMode: 'data',
        fieldDataResults
      });
    }
  }

  render() {
    const {fieldDataResults, viewMode, fieldData} = this.state;
    if (viewMode === 'loading') {
      return (<Loader />);
    } else {
      return (<div className="container p-0">
        <div className="row">
          <div className="col-md-3 col-2">
            <ProfileLinks linkActive="/profile" />
          </div>
          <div className="col-md-9 col-10">

            <DataOverview
                singleEdit={true}
                tableName={tableName}
                fieldData={fieldData}
                fieldDataResults={fieldDataResults}
                languageTable={tableName + "Profile"}
                pageTitle={'Profile'}
                listTitle={'Profile'}
                listSubTitle={''}
                addTitle={''}
                addSubTitle={''}
                editTitle={'Edit Profile'}
                editSubTitle={''}
            />

          </div>
        </div>
      </div>);
    }
  }
}

const mapStateToProps = state => ({
  auth: state.auth.user,
  data: state.pages.data,
});

export default connect(mapStateToProps, {getProfileInfo} )(Profile);
