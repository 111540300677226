import React, { Component, Fragment } from "react";
import { UncontrolledAlert } from "reactstrap";
import { connect } from "react-redux";
import {
  getNotificationList,
  markNotificationRead,
} from "../client/actions/apiActions";
import {
  AlertCircle,
  Bell,
  Home,
  MessageSquare as MessageIcon,
  UserPlus,
} from "react-feather";
import ProfileLinks from "./ProfileLinks";

let notification;

class ProfileNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      browserNotifications: false,
    };
    this.showNotification = this.showNotification.bind(this);
  }

  componentDidMount() {
    this.props.getNotificationList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      if (this.props.data.dataType === "notificationList") {
        this.setState({
          notifications: this.props.data.notifications,
        });
      }
    }
  }

  markNotificationRead = (notificationId) => {
    this.props.markNotificationRead(notificationId);
  };
  showNotification = () => {
    var options = {
      body: "This is the body of the Notification",
      icon: "https://images.pexels.com/photos/853168/pexels-photo-853168.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      dir: "ltr",
    };
    notification = new Notification("Notification Demo", options);
  };

  render() {
    const { notifications, browserNotifications } = this.state;
    return (
      <div className="container p-0">
        <div className="row">
          <div className="col-md-3 col-2">
            <ProfileLinks linkActive="/profile-notifications" />
          </div>
          <div className="col-md-9 col-10">
            <h3 className="py-2 text-center">Notifications</h3>
            <h6 className="text-muted text-center">
              Update your notification settings
            </h6>
            <br />
            <button onClick={this.showNotification}>
              Click to show notification
            </button>
            <br />
            {browserNotifications === true && (
              <Fragment>browserNotifications is true</Fragment>
            )}
            {browserNotifications === false && (
              <Fragment>browserNotifications is false</Fragment>
            )}
            {notifications.length === 0 && (
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <UncontrolledAlert color="warning">
                      <div className="alert-message">
                        There are no new notifications
                      </div>
                    </UncontrolledAlert>
                  </div>
                </div>
              </div>
            )}
            {notifications.map((notification, key) => {
              let color = "primary";
              if (notification.notificationType === "important") {
                color = "danger";
              }
              if (notification.notificationType === "message") {
                color = "dark";
              }
              if (notification.notificationType === "login") {
                color = "primary";
              }
              if (notification.notificationType === "request") {
                color = "success";
              }
              return (
                <UncontrolledAlert
                  color={color}
                  className="alert-outline-coloured"
                  key={key}
                  toggle={() => {
                    this.markNotificationRead(notification.id);
                  }}
                >
                  <div className="alert-icon">
                    {notification.notificationType === "important" && (
                      <AlertCircle size={18} fixedWidth />
                    )}
                    {notification.notificationType === "message" && (
                      <MessageIcon size={18} fixedWidth />
                    )}
                    {notification.notificationType === "login" && (
                      <Home size={18} fixedWidth />
                    )}
                    {notification.notificationType === "request" && (
                      <UserPlus size={18} fixedWidth />
                    )}
                    {notification.notificationType === "notification" && (
                      <Bell size={18} fixedWidth />
                    )}
                  </div>
                  <div className="alert-message">
                    {notification.time} - {notification.notificationSubject}
                    <br />
                    {notification.notificationMessage}
                  </div>
                </UncontrolledAlert>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.user,
  data: state.pages.data,
  dataList: state.pages.dataList,
});

export default connect(mapStateToProps, {
  getNotificationList,
  markNotificationRead,
})(ProfileNotifications);
