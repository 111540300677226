import { useEffect, useState } from "react";
import { withRouter } from "../client/utils/withRouter";
import { useLocation } from "react-router-dom";

const ScrollToTop = (props) => {
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState();

  useEffect(() => {
    if (location !== currentLocation) {
      setCurrentLocation(location);
      window.scrollTo(0, 0);
    }
  }, [location, currentLocation]);

  return props.children;
};

export default withRouter(ScrollToTop);
